import React, { useContext, useEffect } from "react";

import TextComponent from "components/atoms/TextComponent";
import ButtonComponent from "components/atoms/ButtonComponent";
import OnBoardingContainer from "../components/OnBoardingContainer";

import { onBoardingTranslate, translate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { RegistrationContext } from "contexts/RegistrationContext";

import { createTextObjectReusable } from "utils/utils";

import { BOLD, GREEN, H3 } from "constant/constants";

import { colors } from "themes";

function OchyPresentation() {
    const { goToNextStep } = useContext(RegistrationContext);

    useEffect(() => {
        mpTrack('Web App OnBoarding Ochy Presentation Screen Viewed')
    }, []);

    return (
        <OnBoardingContainer radialGradient={true} previous={true}>
            <TextComponent
                multipleText={createTextObjectReusable(
                    onBoardingTranslate('OchyPresentation'),
                    {
                        variant: H3,
                        weight: BOLD,
                    },
                    {
                        variant: H3,
                        weight: BOLD,
                        style: { textAlign: 'center', color: colors.SECONDARY },
                    },
                )}
                variant={H3}
                weight={BOLD}
                center={true}
            />
            <ButtonComponent
                title={translate('LetsStart')}
                type={GREEN}
                onClick={goToNextStep}
                sx={{ width: '15vw', height: '6vh', alignSelf: 'center', marginTop: '5vh' }}
            />
        </OnBoardingContainer>
    );
}

export default OchyPresentation;