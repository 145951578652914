import React, { useEffect, useState } from 'react';

import TextComponent from 'components/atoms/TextComponent';
import MultipleButton from 'components/molecules/MultipleButton';
import OnBoardingContainer from '../../components/OnBoardingContainer';
import OnBoardingTemplate from '../../components/OnBoardingTemplate';
import { videoTutosContent, videoTutoTitle } from '../../components/VideoTutosData';

import { translate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import { checkUserPermission } from 'utils/utils';

import {
  COACH,
  MEDIUM,
} from 'constant/constants';

import { colors } from 'themes';

function OnBoardingGoodVideo() {
  const [videoUri, setVideoUri] = useState(videoTutosContent[checkUserPermission([COACH], true) ? 0 : 2]?.videoUri);
  const [index, setIndex] = useState(checkUserPermission([COACH], true) ? 0 : 2);
  const [type, setType] = useState(checkUserPermission([COACH], true) ? 'SideViewRunning' : 'BackViewTreadmill');

  const videoTutoContentUpdated = checkUserPermission([COACH], true) ? [...videoTutosContent]?.filter((_, index) => index !== 2) :
    [...videoTutosContent]?.filter((_, index) => index !== 1)

  const config = [
    { title: 'SideViewRunning' },
    { title: 'SideViewTreadmill' },
    { title: 'BackViewTreadmill' },
  ];

  useEffect(() => {
    const index = videoTutoTitle?.findIndex((title) => title === type);
    setVideoUri(videoTutoContentUpdated[index]?.videoUri);
    setIndex(index);
  }, [type]);

  const ContentText = ({ text, good }) => {
    return (
      <div style={{ flexDirection: 'row', marginBottom: '1vh', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '1vw' }}>
        <div
          style={{
            borderRadius: '50%',
            height: '10px',
            width: '12px',
            backgroundColor: good ? colors.GREEN : colors.RED,
            border: `5px solid ${good
              ? colors.RADIAL_GREEN_BACKGROUND_NO_OPACITY
              : colors.RADIAL_RED_BACKGROUND_NO_OPACITY}`,
            display: 'flex',
          }}
        />
        <TextComponent
          text={translate(text)}
          weight={MEDIUM}
          sx={{
            marginLeft: '1vw',
            width: '100%',
            color: good ? colors.GREEN : colors.RED,
            fontSize: '1.2vw',
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    checkUserPermission([COACH], true)
      ? mpTrack('Web App OnBoarding Coach Video Tuto Screen Viewed')
      : mpTrack('Web App OnBoarding Professional Video Tuto Screen Viewed');
  }, []);


  if (!videoUri) return null;
  return (
    <OnBoardingContainer previous next>
      <OnBoardingTemplate>

        <div style={videoTutoContentUpdated[index]?.vertical ? styles.vertical : styles.horizontal}>

          <video
            style={videoTutoContentUpdated[index]?.vertical ? styles.videoVertical : styles.videoHorizontal}
            controls={false}
            loop={true}
            autoPlay={true}
            muted={true}
            src={videoUri}
          />

          <div style={{ display: 'flex', flexDirection: 'column ', justifyContent: 'center', }}>
            {videoTutoContentUpdated[index]?.videoTextGood?.map((title, index) => (
              <ContentText key={index} text={title.text} good />
            ))}

            {videoTutoContentUpdated[index]?.videoTextBad?.map((title, index) => (
              <ContentText key={index} text={title.text} />
            ))}
          </div>
        </div>

      </OnBoardingTemplate>
      <MultipleButton config={config} navigation={type} setNavigation={setType} />

    </OnBoardingContainer>

  );
}
const styles = {
  horizontal: {
    display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',
  },
  vertical: { display: 'flex', flexDirection: 'row', width: '60%', alignItems: 'center', height: '100%' },
  videoHorizontal: { height: '35vh' },
  videoVertical: { width: '40%' },
};
export default OnBoardingGoodVideo;
