import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { colors } from "themes";
import ReactCountryFlag from "react-country-flag";
import { PHONE_PREFIX_LIST } from "constant/contactSalesConstants";

function PickerPhoneComponent({ defaultCountryCode, disabled, height, setDialCode }) {
    const config = PHONE_PREFIX_LIST?.map(item => ({
        value: item?.dial_code,
        code: item?.code,
        name: item?.name,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactCountryFlag
                    countryCode={item.code}
                    svg
                    style={{
                        marginRight: '10px', width: '1.5em',
                        height: '1.5em',
                    }}
                />
                <span>{`${item.name} (${item.dial_code})`}</span>
            </div>
        ),
    }));
    const getDialCodeByCountryCode = (countryCode) => {
        const country = PHONE_PREFIX_LIST.find(item => item?.code === countryCode);
        return country ? country?.dial_code : null;
    };

    const [value, setValue] = useState(getDialCodeByCountryCode(defaultCountryCode) || config[0]?.value);

    useEffect(() => {
        if (setDialCode) {
            setDialCode(value);
        }
    }, [value]);


    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        setValue(event?.target?.value);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                onChange={handleChange}
                onOpen={handleOpen}
                onClose={handleClose}
                sx={{ ...styles.picker, ...{ height: height } }}
                disabled={disabled}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: '50vh',
                            overflowY: 'auto',
                        },
                    },

                }}
                IconComponent={() => (
                    <div style={styles.arrow} />
                )}
            >
                {config?.map((item, index) => {
                    return (
                        <MenuItem key={index} value={item.value}>
                            {open ? item.label : (
                                <ReactCountryFlag
                                    countryCode={item.code}
                                    svg
                                    style={{
                                        marginRight: '10px', width: '1.5em',
                                        height: '1.5em',
                                    }}
                                />
                            )}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
}

const styles = {
    picker: {
        color: colors.WHITE,
        backgroundColor: colors.BLACK_OPACITY_70,
        borderRadius: "10px",
        fontFamily: 'Arial, sans-serif',
    },
    arrow: {
        width: '0',
        height: '0',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: '5px solid white',
        position: 'absolute',
        right: '10px',
    },
};

export default PickerPhoneComponent;
