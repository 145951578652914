import React, { useEffect } from "react";

import OnBoardingContainer from "../../components/OnBoardingContainer";
import OnBoardingTemplate from "../../components/OnBoardingTemplate";
import AnimatedText from "components/atoms/AnimatedText";
import TextComponent from "components/atoms/TextComponent";

import { onBoardingTranslate, translate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { checkUserPermission } from "utils/utils";

import { BOLD, COACH, H4, REGISTRATION_DATA } from "constant/constants";

import { colors, images } from "themes";

function TestimonialHighlight() {

    const userType = JSON.parse(localStorage.getItem(REGISTRATION_DATA))?.userType;

    useEffect(() => {
        checkUserPermission([COACH], true)
            ? mpTrack('Web App OnBoarding Coach Testimonial Hilight Screen Viewed')
            : mpTrack('Web App OnBoarding Professional Testimonial Hilight Screen Viewed');
    }, []);

    const testimonial = {
        coach: {
            photo: images.TESTIMONIAL_PICTURE,
            name: translate('Dr') + 'Kimberly Melvan',
            profession: onBoardingTranslate('TestimonialCoachRole'),
            comment: onBoardingTranslate('TestimonialCoach')
        },
        professional: {
            photo: images.TESTIMONIAL_PICTURE_PRO,
            name: 'Marc Guillet',
            profession: onBoardingTranslate('TestimonialProRole'),
            comment: onBoardingTranslate('TestimonialPro')
        }
    }


    return (
        <OnBoardingContainer previous next path={''}>
            <OnBoardingTemplate
                title={onBoardingTranslate('TestimonialTitle')}
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '55%', backgroundColor: 'black', borderRadius: 15, marginTop: '10vh', position: 'relative' }}>
                        <TextComponent
                            text="“"
                            sx={{ color: colors.WHITE_OPACITY_20, position: 'absolute', top: '-15vh', right: '2vw', fontSize: '30vh', zIndex: 1 }}
                        />
                        <img
                            src={testimonial[userType].photo}
                            alt="testimonial"
                            style={{ height: '15vh', width: '15vh', borderRadius: '10vh', marginTop: '3vh', marginLeft: '3vh' }}
                        />
                        <TextComponent
                            text={testimonial[userType].name}
                            variant={H4}
                            weight={BOLD}
                            sx={{ color: colors.WHITE, marginTop: '2vh', marginLeft: '3vh', }}
                        />
                        <TextComponent
                            text={testimonial[userType].profession}
                            sx={{ color: colors.WHITE_OPACITY_60, marginTop: '1vh', marginLeft: '3vh', fontSize: '0.8vw' }}
                        />
                        <AnimatedText
                            text={testimonial[userType].comment}
                            sx={{ color: colors.WHITE, marginTop: '2vh', marginLeft: '3vh', width: '90%', marginBottom: '3vh' }}
                        />
                    </div>

                </div>
            </OnBoardingTemplate>
        </OnBoardingContainer >
    )
}

export default TestimonialHighlight;