import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {PersistGate} from 'redux-persist/integration/react';

import {persistor, store} from './store';
import {Provider} from 'react-redux';

import {App} from 'App';
import {colors} from 'themes';

const root = ReactDOM.createRoot(document.getElementById('root'));

document.body.style.margin = '0';
document.body.style.padding = '0';
document.body.style.minHeight = '100vh';
document.body.style.backgroundColor = colors.BACKGROUND;

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);
