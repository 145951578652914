import React, { useContext, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import { store } from 'store';

import TextComponent from 'components/atoms/TextComponent';
import ButtonComponent from 'components/atoms/ButtonComponent';
import OnBoardingContainer from '../../components/OnBoardingContainer';
import OnBoardingTemplate from '../../components/OnBoardingTemplate';

import { getUserTypeNumber } from 'services/uiApi';
import { onBoardingTranslate, translate } from 'services/remoteConfig';
import { mpIdentify, mpTrack } from 'services/mixpanel';

import { RegistrationContext } from 'contexts/RegistrationContext';

import {
  createTextObjectReusable,
} from 'utils/utils';

import { ATHLETE, BOLD, GREEN, H1, H4, REGISTRATION_DATA } from 'constant/constants';

import { colors } from 'themes';

import { ReactComponent as Trophy } from 'assets/svg/bottomBarScore.svg';

function OnBoardingUserNumber() {
  const { goToNextStep } = useContext(RegistrationContext);

  const [numberOfAthletes, setNumberOfAthletes] = useState(0);
  mpIdentify();

  const userType = JSON.parse(localStorage.getItem(REGISTRATION_DATA)).userType
    ? JSON.parse(localStorage.getItem(REGISTRATION_DATA)).userType
    : ATHLETE;

  useEffect(() => {
    if (userType === 'athlete') {
      mpTrack('Web App OnBoarding Welcoming Athlete Screen Viewed');
    } else {
      mpTrack('Web App OnBoarding Welcoming Coach Screen Viewed');
    }
  }, []);

  useEffect(() => {
    const getUserTypeNumbers = async () => {
      const data = await getUserTypeNumber();
      if (data && userType) {
        setNumberOfAthletes(data[userType]);
      }
    };
    getUserTypeNumbers();
  }, [userType]);

  function Number({ n }) {
    const { number } = useSpring({
      from: { number: 0 },
      number: n,
      config: { mass: 1, tension: 20, friction: 10, duration: 1000 },
      delay: 200,
    });
    return <animated.div>{number.to(n => n.toFixed(0))}</animated.div>;
  }


  const getText = () => {
    if (userType === ATHLETE) {
      return (
        onBoardingTranslate('WelcomingUser') +
        onBoardingTranslate('WelcomingUserAthlete')
      );
    } else {
      return onBoardingTranslate('WelcomingCoach');
    }
  };


  return (
    <OnBoardingContainer radialGradient={true} previous>
      <OnBoardingTemplate>
        <div style={styles.trophy}>
          <Trophy height={25} width={25} fill={colors.GREEN} />
        </div>
        <TextComponent
          text={store?.getState()?.user?.fullName}
          center={true}
          variant={H1}
          weight={BOLD}
          sx={{ marginBottom: '2vh' }}
        />
        <TextComponent
          text={getText()}
          center={true}
          variant={H4}
          weight={BOLD}
        />
        <Typography
          variant={H1}
          component={'div'}
          fontWeight={BOLD}
          sx={{ color: colors.WHITE, marginTop: 5 }}>
          <Number n={numberOfAthletes} />
        </Typography>
        <TextComponent
          multipleText={createTextObjectReusable(
            userType === ATHLETE
              ? onBoardingTranslate('WelcomingUserAthlete2')
              : onBoardingTranslate('WelcomingUserCoach2'),
            {
              variant: H4,
              weight: BOLD,
            },
          )}
          center={true}
        />
        <ButtonComponent
          title={translate('Next')}
          type={GREEN}
          onClick={goToNextStep}
          sx={{ width: '15vw', height: '6vh', alignSelf: 'center', marginTop: '5vh' }}
        />
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}

const styles = {
  trophy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.GREEN_15_HEX,
    border: '2px solid rgba(255, 255, 255, 0.15)',
    borderRadius: '40px',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
    height: '50px',
    width: '50px',
    marginBottom: '30px',
    position: 'relative',
  },

};

export default OnBoardingUserNumber;
