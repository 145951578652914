import React, { useContext, useEffect } from "react";

import { store } from "store";

import OnBoardingContainer from "../../components/OnBoardingContainer";
import ButtonComponent from "components/atoms/ButtonComponent";
import OnBoardingTemplate from "../../components/OnBoardingTemplate";
import TextComponent from "components/atoms/TextComponent";

import { onBoardingTranslate, translate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { RegistrationContext } from "contexts/RegistrationContext";

import { checkUserPermission } from "utils/utils";

import { BOLD, COACH, GREEN, H3, H4, MEDIUM } from "constant/constants";

import { colors } from "themes";

function VideoTutoIntro() {
    const { goToNextStep } = useContext(RegistrationContext);

    useEffect(() => {
        checkUserPermission([COACH], true)
            ? mpTrack('Web App OnBoarding Coach Video Tuto Intro Screen Viewed')
            : mpTrack('Web App OnBoarding Professional Video Tuto Intro Screen Viewed');
    }, []);

    return (
        <OnBoardingContainer previous radialGradient>
            <OnBoardingTemplate>

                <TextComponent
                    text={onBoardingTranslate('VideoTutoIntroTitle')}
                    variant={H3}
                    weight={BOLD}
                    center={true}
                />
                <TextComponent
                    text={store?.getState()?.user?.fullName + ' !'}
                    variant={H3}
                    weight={BOLD}
                    center={true}
                    sx={{ color: colors.WHITE, marginTop: '2vh', textAlign: 'center' }}

                />
                <TextComponent
                    text={onBoardingTranslate('VideoTutoIntroText')}
                    center={true}
                    variant={H4}
                    weight={MEDIUM}
                    sx={{ color: colors.WHITE, marginTop: '2vh', textAlign: 'center' }}
                />

                <ButtonComponent
                    title={translate('Next')}
                    type={GREEN}
                    onClick={goToNextStep}
                    sx={{ width: '15vw', height: '6vh', alignSelf: 'center', marginTop: '5vh' }}
                />
            </OnBoardingTemplate>
        </OnBoardingContainer>
    )
}

export default VideoTutoIntro;