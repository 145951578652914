import ErrorPage from "Error-page";
import React from "react";
import { Route, Routes } from "react-router-dom";
import OchyPresentation from "screen/auth/registration/onBoarding/common/OchyPresentation";
import OnBoardingWelcome from "screen/auth/registration/onBoarding/common/OnBoardingWelcome";
import UserTypeSelection from "screen/auth/registration/onBoarding/common/UserTypeSelection";

function CommonStack() {
    return (
        <Routes>
            <Route path="*" element={<ErrorPage />} />
            <Route
                path="/"
                element={<OnBoardingWelcome />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/ochy-presentation"
                element={<OchyPresentation />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/select-user-type"
                element={<UserTypeSelection />}
                errorElement={<ErrorPage />}
            />
        </Routes>
    )
}

export default CommonStack;
