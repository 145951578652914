export const EXEMPLE_ANALYSIS = {
  de: 'analysis_sideView_de',
  en: 'analysis_sideView_en',
  fr: 'analysis_sideView_fr',
  zh: 'analysis_sideView_zh',
  pt: 'analysis_sideView_pt',
  it: 'analysis_sideView_it',
  es: 'analysis_sideView_es',
  ja: 'analysis_sideView_ja',
  ko: 'analysis_sideView_ko',
};

export const EXEMPLE_ANALYSIS_SIDEVIEW_PRO = {
  de: 'analysis_sideView_pro_de',
  en: 'analysis_sideView_pro_en',
  fr: 'analysis_sideView_pro_fr',
  zh: 'analysis_sideView_pro_zh',
  pt: 'analysis_sideView_pro_pt',
  it: 'analysis_sideView_pro_it',
  es: 'analysis_sideView_pro_es',
  ja: 'analysis_sideView_pro_ja',
  ko: 'analysis_sideView_pro_ko',
};

export const EXEMPLE_ANALYSIS_BACKVIEW = {
  de: 'analysis_backView_de',
  en: 'analysis_backView_en',
  fr: 'analysis_backView_fr',
  zh: 'analysis_backView_zh',
  pt: 'analysis_backView_pt',
  it: 'analysis_backView_it',
  es: 'analysis_backView_es',
  ja: 'analysis_backView_ja',
  ko: 'analysis_backView_ko',
};
