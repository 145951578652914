import { Typography } from "@mui/material";
import { BODY_2, LIGHT } from "constant/constants";
import React from "react";
import { useSprings, animated } from 'react-spring';
import { colors } from "themes";


function AnimatedText({ text, sx, variant, weight, color }) {

    const springs = useSprings(
        text?.length,
        text?.split('').map((_, index) => ({
            from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
            to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
            delay: index * 20,
        })),
    );

    return (
        <Typography
            variant={variant || BODY_2}
            fontWeight={weight || LIGHT}
            color={color || colors?.WHITE_OPACITY_60}
            sx={sx}
            component="span">
            {springs?.map((props, index) => (
                <animated.span key={index} style={props}>
                    {text[index]}
                </animated.span>
            ))}
        </Typography>
    );

}

export default AnimatedText;