import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import ErrorPage from './Error-page';
import Analysis from './screen/analysis/AnalysisViewDetailsScreen/Analysis';
import Login from './screen/auth/Login';
import AnalysisList from './screen/analysis/AnalysisList';
import Header from 'components/template/Header';
import UploadScreen from 'screen/upload/UploadScreen';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from 'utils/firebaseInit';
import {ErrorBoundary} from 'react-error-boundary';
import {USER_LOCALE, useRemoteConfigInit} from 'services/remoteConfig';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {resetProfileStore, updateProfileStore} from 'slices/userSlice';
import {getUserInfoFromId} from 'services/userApi';

import PrivacyPolicy from 'screen/pp&tos/PrivacyPolicy';
import TermsNConditions from 'screen/pp&tos/TermsNConditions';
import ForgotPassword from 'screen/auth/registration/ForgotPassword';

import {EN, ENGLISH, FR, FRENCH} from 'constant/constants';

import {ThemeProvider, createMuiTheme} from '@mui/material';
import NavigationPanel from 'components/template/NavigationPanel';
import {colors} from 'themes';
import AthletesList from 'screen/athlete/AthletesList';
import AddAthlete from 'screen/athlete/AddAthlete';

import {isMobile} from 'react-device-detect';
import MobileRedirection from 'screen/auth/components/MobileRedirection';
import {
  resetUnityStore,
  selectUnityType,
  setUnity,
  setUnityType,
} from 'slices/unitySlice';
import AnalysisShared from 'screen/analysis/AnalysisViewDetailsScreen/AnalysisShared';
import HowToDeleteAccount from 'screen/howToDeleteAccount/HowToDeleteAccount';
import ExerciceScreen from 'screen/exercices';
import ProfileScreen from 'screen/profile';
import Leaderboard from 'screen/Leaderboard/Leaderboard';
import Registration from 'screen/auth/registration/Registration';
import LoginOrRegister from 'screen/auth/LoginOrRegister';
import CompareAnalysis from 'screen/analysis/CompareAnalysis/CompareAnalysis';
import AdidasActivationRegistration from 'screen/auth/registration/AdidasActivationRegistration';
import AdidasActivationRegistrationSuccess from 'screen/auth/registration/AdidasActivationRegistrationSuccess';
import AdidasActivationLogin from 'screen/auth/registration/AdidasActivationLogin';
import {updateCorrectLocalStorageJsonVersion} from 'slices/analysisSlice';
import {getLocalStorageVersion} from 'utils/jsonAdaptater';
import BackOffice from 'screen/backOffice/BackOffice';
import {handleLogout} from 'utils/utils';
import {RegistrationProvider} from 'contexts/RegistrationContext';
import RegistrationRoutes from 'navigation/registration/RegistrationRoutes';
import {useUsageChanges} from 'hooks/useUsageChanges';

export function App() {
  const dispatch = useDispatch();
  const [user, loading] = useAuthState(auth);
  const remoteLoading = useRemoteConfigInit();
  const [configLoaded, setConfigLoaded] = useState(false);
  const userSlice = useSelector(state => state?.user);
  const unityType = useSelector(selectUnityType);
  const navigation = useLocation();
  useUsageChanges();

  const theme = createMuiTheme({
    typography: {
      fontFamily: ['Space Grotesk', 'sans-serif'].join(','),
    },
  });
  const [loginLoading, setLoginLoading] = useState(false);
  const navigate = useNavigate();

  //setup correct local Storage version number
  //setting up local storage json version number
  useEffect(() => {
    async function setLocalStorageJsonVersion() {
      const jsonVersion = await getLocalStorageVersion();
      if (jsonVersion) {
        dispatch(updateCorrectLocalStorageJsonVersion(jsonVersion));
      }
    }
    setLocalStorageJsonVersion();
  }, []);

  useEffect(() => {
    if (!user && !loading) {
      handleLogout(dispatch, navigate);
      return;
    }
    if (unityType === '' || !unityType) {
      if (USER_LOCALE === ENGLISH.code) {
        dispatch(setUnityType(EN));
        dispatch(setUnity(EN));
      } else {
        dispatch(setUnityType(FR));
        dispatch(setUnity(FR));
      }
    }
    if (userSlice?.userID === 'Unknown' && user) {
      setLoginLoading(true);
      getUserInfoFromId(user?.uid)
        .then(data => {
          const userData = data;
          userData.userID = user?.uid;
          userData.userType = userData?.type;
          dispatch(updateProfileStore(userData));
          setLoginLoading(false);
        })
        .catch(error => {
          console.log('error in getUserInfoFromId', error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (remoteLoading) return;
    setConfigLoaded(true);
  }, [remoteLoading]);

  const isAffiliation = () => {
    if (navigation?.pathname?.includes('adidas')) {
      return true;
    }
    return false;
  };

  if (loading || !configLoaded) return null;

  function authRoute() {
    if (isAffiliation()) {
      return (
        <Routes>
          <Route
            path="/ochy-adidas"
            element={<AdidasActivationRegistration />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/ochy-adidas-login"
            element={<AdidasActivationLogin />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/ochy-adidas-activation-success"
            element={<AdidasActivationRegistrationSuccess />}
            errorElement={<ErrorPage />}
          />
        </Routes>
      );
    }
    if (
      (userSlice?.userID !== 'Unknown' && userSlice?.registered === true) ||
      loginLoading
    ) {
      return (
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route
            path="/"
            element={<AnalysisList loginLoading={loginLoading} />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/analysis-athlete/:id"
            element={<AnalysisList />}
            errorElement={<ErrorPage />}
          />

          <Route
            path="/analysis/:id"
            element={<Analysis />}
            errorElement={<ErrorPage />}
          />

          <Route
            path="/exercice/:id"
            element={<ExerciceScreen />}
            errorElement={<ErrorPage />}
          />

          <Route
            path="/analysis-shared/:id"
            element={<AnalysisShared />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/upload-video/"
            element={<UploadScreen />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/upload-video/:id"
            element={<UploadScreen />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/athletes"
            element={<AthletesList />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/profile"
            element={<ProfileScreen />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/compare-analysis"
            element={<CompareAnalysis />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/add-athlete"
            element={<AddAthlete />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/how-to-delete-account"
            element={<HowToDeleteAccount />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/leaderboard"
            element={<Leaderboard />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/backoffice"
            element={userSlice?.admin === true ? <BackOffice /> : <ErrorPage />}
            errorElement={<ErrorPage />}
          />
        </Routes>
      );
    } else if (
      userSlice?.userID !== 'Unknown' &&
      userSlice?.registered === false
    ) {
      return (
        <RegistrationProvider>
          <RegistrationRoutes />
        </RegistrationProvider>
      );
    } else {
      return (
        <ErrorBoundary FallbackComponent={<ErrorPage />}>
          <Routes>
            <Route path="*" element={<ErrorPage />} />
            <Route
              path="/"
              element={<LoginOrRegister />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/ochy-adidas"
              element={<AdidasActivationRegistration />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/login"
              element={<Login />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/register"
              element={<Registration />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/analysis-shared/:id"
              element={<AnalysisShared />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/exercice/:id"
              element={<ExerciceScreen />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/privacyPolicy"
              element={<PrivacyPolicy />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/termsAndConditions"
              element={<TermsNConditions />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/forgotPassword"
              element={<ForgotPassword />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/how-to-delete-account"
              element={<HowToDeleteAccount />}
              errorElement={<ErrorPage />}
            />
          </Routes>
        </ErrorBoundary>
      );
    }
  }

  const letPath = () => {
    if (navigation?.pathname?.includes('exercice')) {
      return false;
    }
    if (navigation?.pathname?.includes('analysis-shared')) {
      return false;
    }
    if (navigation?.pathname?.includes('adidas')) {
      return false;
    } else return true;
  };

  const enableSide = () => {
    if (isMobile && navigation?.pathname?.includes('analysis-shared')) {
      return false;
    }
    if (navigation?.pathname?.includes('ochy-adidas')) {
      return false;
    }
    if (navigation?.pathname === '/leaderboard') {
      return false;
    } else return true;
  };

  if (isMobile && letPath()) {
    setTimeout(() => {
      window.open('https://ochy.page.link/kgNQ');
    }, 1000);
    return <MobileRedirection />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        {userSlice?.registered === true && enableSide() ? (
          <>
            <Header isAuth={user ? true : false} />
            <NavigationPanel />
          </>
        ) : null}
        <div
          style={
            userSlice?.registered === true && enableSide()
              ? styles.containerAuth
              : styles.container
          }>
          {authRoute()}
        </div>
      </ThemeProvider>
    );
  }
}

const styles = {
  containerAuth: {
    width: '87.5%',
    float: 'right',
    marginTop: '0.5%',
    borderRadius: 10,
    minHeight: '92vh',
    position: 'relative',
    overflowY: 'auto',
  },
  container: {
    width: '100%',
    overflowY: 'auto',
    height: '100vh',
  },
};
