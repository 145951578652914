import React, { useEffect } from "react";

import { useSpring, animated } from "react-spring";

import OnBoardingContainer from "../../components/OnBoardingContainer";
import OnBoardingTemplate from "../../components/OnBoardingTemplate";

import { onBoardingTranslate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel"
    ;
import { checkUserPermission } from "utils/utils";

import { COACH } from "constant/constants";

import { colors, images } from "themes";

function QuickAnalysisHighlight() {

    useEffect(() => {
        checkUserPermission([COACH], true)
            ? mpTrack('Web App OnBoarding Coach Quick Analysis Highlight Screen Viewed')
            : mpTrack('Web App OnBoarding Professional Quick Analysis Highlight Screen Viewed');
    }, []);

    const [props, set] = useSpring(() => ({
        height: 0,
        config: { duration: 3000 },
    }));

    const springProps = useSpring({
        from: { clipPath: 'inset(100% 0 0 0)' },
        to: { clipPath: 'inset(0 0 0 0)' },
        config: { duration: 3000 },
    });

    useEffect(() => {
        set({ height: 100 });
    }, [set]);

    return (
        <OnBoardingContainer previous next>
            <OnBoardingTemplate
                title={onBoardingTranslate("QuickAnalysisTitle")}
                text={onBoardingTranslate("QuickAnalysisDescPro")}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                        position: "relative",
                        width: "50%",
                    }}
                >
                    <img
                        src={images.RUNNER_NO_SEGMENTS}
                        alt="runner"
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                            position: "absolute",
                        }}
                    />

                    <animated.div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: props.height.to(h => `${h}%`),
                            borderTop: `5px solid ${colors.SECONDARY}`,
                            overflow: "hidden",
                            backgroundColor: colors?.SECONDARY_OPACITY_10,
                            width: "100%",
                        }}
                    />
                    <animated.div
                        style={{
                            ...springProps,
                            position: 'absolute',
                            bottom: 0,
                            overflow: 'hidden',
                            height: '100%',

                        }}
                    >
                        <img
                            src={images.RUNNER_SEGMENTS}
                            alt="runner"
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </animated.div>
                </div>
            </OnBoardingTemplate>
        </OnBoardingContainer>
    );
}

export default QuickAnalysisHighlight;
