import React, { useState, useEffect } from 'react';
import TextComponent from '../../atoms/TextComponent';
import { colors } from 'themes';
import { isFreeTrialExpired, isPremium } from 'utils/utils';
import { translate } from 'services/remoteConfig';
import { useSelector } from 'react-redux';
import { updateUsages } from 'services/usageApi';

function FreeTrialCountdown({ targetDate }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
    const usageSlice = useSelector((state) => state?.usage);

    function calculateTimeLeft(targetDate) {
        const now = new Date();
        const difference = new Date(targetDate) - now;
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }

    useEffect(() => {
        if (!usageSlice?.freeTrialTimeStamp) return;
        if (isFreeTrialExpired() && usageSlice?.monthlyCreditNumber > 0) {
            updateUsages({ monthlyCreditNumber: 0 });
        }
        if (isFreeTrialExpired()) {
            return setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate, timeLeft?.minutes]);

    if ((timeLeft.days || timeLeft.hours || timeLeft.minutes || timeLeft.seconds) && !isPremium())
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginRight: '3vw', }}>
                    <TextComponent
                        text={translate('FreeTrialDuration')}
                        sx={{ color: colors.WHITE, fontSize: '1.2vw', }} />
                    {!isFreeTrialExpired() && <TextComponent
                        text={translate('OrTenAnalysis')}
                        sx={{ fontSize: '0.8vw', color: colors.WHITE_OPACITY_60 }} />}
                </div>
                <TextComponent
                    text={`${String(timeLeft.days).padStart(2, '0')}:${String(timeLeft.hours).padStart(2, '0')}:${String(timeLeft.minutes).padStart(2, '0')}:${String(timeLeft.seconds).padStart(2, '0')}`}
                    sx={{ color: colors.GREEN, fontSize: '1.5vw', width: '10vw' }} />
            </>
        );
    else if (usageSlice?.freeTrialTimeStamp) return (
        <TextComponent
            text={translate('FreeTrialsFinished')}
            sx={{ color: colors.WHITE, fontSize: '1.2vw', }} />
    );
    else return null;
};

export default FreeTrialCountdown;
