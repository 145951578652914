import {store} from 'store';
import {collection, doc, getDoc, setDoc} from 'firebase/firestore';

import {auth, firestore} from 'utils/firebaseInit';
import {checkUserPermission} from 'utils/utils';
import {translate} from './remoteConfig';
import {getAuth} from 'firebase/auth';

const USAGE_COLLECTION = 'usages';

export async function getUsage(uid = null) {
  const user = auth?.currentUser;
  if (user) {
    const userId = uid || auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, USAGE_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);
      if ((await docs).exists()) {
        const report = (await docs).data();
        return report;
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function removeCredit() {
  const usageStore = store?.getState()?.usage;
  const user = auth?.currentUser;
  if (
    usageStore?.yearlyPlan ||
    (usageStore?.monthlyPlan && checkUserPermission[('coach', 'professional')])
  )
    return;
  if (user) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, USAGE_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);
      if ((await docs).exists()) {
        const report = (await docs).data();
        if (report?.monthlyCreditNumber > 0) {
          setDoc(
            ref,
            {monthlyCreditNumber: report.monthlyCreditNumber - 1},
            {merge: true},
          );
          report.monthlyCreditNumber -= 1;
        } else if (report?.trial > 0) {
          setDoc(ref, {trial: report.trial - 1}, {merge: true});
          report.trial -= 1;
        } else if (report?.creditNumber > 0) {
          setDoc(ref, {creditNumber: report.creditNumber - 1}, {merge: true});
          report.creditNumber -= 1;
        }
        return report;
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function addCredit(number) {
  const user = auth?.currentUser;
  if (user) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, USAGE_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);

      if ((await docs).exists()) {
        const report = (await docs).data();
        if (report.creditNumber >= 0 && number >= 0) {
          setDoc(
            ref,
            {creditNumber: report?.creditNumber + number},
            {merge: true},
          );
          return report.creditNumber + number;
        }
      } else {
        setDoc(ref, {creditNumber: number}, {merge: true});
        return number;
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function addQuarterlyPlan(expiredDate = null) {
  const user = auth?.currentUser;
  if (user) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, USAGE_COLLECTION);
      const ref = doc(collectionRef, userId);
      const expireDate = new Date(
        new Date().setMonth(new Date().getMonth() + 3),
      );
      const creditExpires = new Date(
        new Date().setMonth(new Date().getMonth() + 1),
      );
      setDoc(
        ref,
        {
          monthlyPlan: true,
          monthlyCreditNumber: 3,
          monthlyCreditExpired: creditExpires,
          subscriptionExpiresDate: expiredDate ? expiredDate : expireDate,
          quaterlyPlan: true,
        },
        {merge: true},
      );
      return true;
    } catch (e) {
      console.log(e);
    }
  }
}

export async function updateUsages(usage, uid = null) {
  const user = auth?.currentUser;
  if (user) {
    const userId = uid || auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, USAGE_COLLECTION);
      const ref = doc(collectionRef, userId);
      setDoc(ref, usage, {merge: true});
      return true;
    } catch (e) {
      console.log(e);
    }
  }
}

export async function postRequestOnHubspot(formValues) {
  const url = process.env.REACT_APP_API_KEY + '/create/deal';
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const idToken = await currentUser.getIdToken();

  const data = {
    email: formValues['email'],
    phone_number: formValues['phoneNumber'],
    company_name: formValues['companyName'],
    number_of_analysis_per_month: Number(formValues['analysisNumber']),
    role: formValues['role'],
    job_title:
      formValues['jobTitleOther'] &&
      formValues['jobTitle'] === translate('Other')
        ? formValues['jobTitleOther']
        : formValues['jobTitle'],
    number_of_users: Number(formValues['usersNumber']),
    notes: formValues['notes'],
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: `${idToken}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (e) {
    console.log('error in postRequestOnHubspot', e);
  }
}
