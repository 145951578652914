import React from 'react';

import TextComponent from 'components/atoms/TextComponent';
import StatusTag from './StatusTag';

import { translate } from 'services/remoteConfig';

import { BOLD } from 'constant/constants';

import { colors } from 'themes';
import { isFreeTrialExpired } from 'utils/utils';

const UsageInfosDisplay = ({ legacy, usageSlice }) => {
    const {
        yearlyPlan,
        monthlyPlan,
        monthlyCreditNumber,
        creditNumber,
        monthlyCreditExpired,
        numberCreditToRenew,
        customPlan,
    } = usageSlice || {};

    const monthlyCreditExpiredDate = new Date(new Date(monthlyCreditExpired)?.setDate(new Date(monthlyCreditExpired)?.getDate() + 1))?.toLocaleDateString();

    const getMaxCred = () => {
        if (customPlan) return numberCreditToRenew;
        else if (!isFreeTrialExpired()) return '10';
        return '0';
    };

    const renderSubscriptionDisplay = (planType, planAmount) => (
        <>
            <div style={styles.subContainer}>
                <TextComponent
                    sx={styles.boldText}
                    text={translate(planType) + ' ' + planAmount}
                />
                <StatusTag />
            </div>
            {planAmount === 'Unlimited' && (
                <div style={styles.planContainer}>
                    <div style={styles.unlimitedContainer}>
                        <TextComponent
                            sx={styles.unlimitedText}
                            text={translate('UnlimitedAnalysis')}
                        />
                    </div>
                </div>
            )}
        </>
    );

    const displaySubscription = () => {
        if (yearlyPlan && !customPlan)
            return renderSubscriptionDisplay('YearlyPlan', 'Unlimited');

        if (monthlyPlan && !customPlan)
            return renderSubscriptionDisplay('MonthlyPlan', 'Unlimited');

        if (yearlyPlan && customPlan)
            return renderSubscriptionDisplay('YearlyPlan', numberCreditToRenew);

        if (monthlyPlan && customPlan)
            return renderSubscriptionDisplay('MonthlyPlan', numberCreditToRenew);
        if (!isFreeTrialExpired()) {
            return (
                <TextComponent
                    sx={{ ...styles.boldText, color: colors.GREEN }}
                    text={translate('FreeTrialDuration')}
                />
            )
        }

        return (
            <div style={styles.subContainer}>
                <TextComponent
                    sx={styles.boldText}
                    text={translate('NoActivePlan')}
                />
            </div>
        );
    };

    return (
        <div style={{ width: '100%' }}>
            <div>
                <TextComponent
                    text={translate('YourPlan') + ':'}
                    sx={styles.planTitle}
                />
                {displaySubscription()}
            </div>
            {!legacy && (
                <div style={styles.planContainer}>
                    <div style={styles.textContainer}>
                        <TextComponent
                            text={translate('MonthlyAnalysis') + ' : '}
                            sx={{ fontSize: '0.8vw' }}
                        />
                        <TextComponent
                            sx={styles.creditNumberText}
                            text={monthlyCreditNumber + ' / ' + getMaxCred()}
                            weight={BOLD}
                        />
                    </div>
                    {monthlyCreditExpiredDate !== 'Invalid Date' && <div style={styles.textContainer}>
                        <TextComponent
                            sx={{ fontSize: '0.8vw' }}
                            text={translate('CreditsRenewal') + ' : '}
                        />
                        <TextComponent
                            sx={styles.creditNumberText}
                            text={monthlyCreditExpiredDate}
                            weight={BOLD}
                        />
                    </div>}
                    <div style={styles.textContainer}>
                        <TextComponent
                            sx={{ fontSize: '0.8vw' }}
                            text={translate('BoughtAnalysis') + ' : '}
                        />
                        <TextComponent
                            sx={styles.creditNumberText}
                            text={creditNumber || 0}
                            weight={BOLD}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    boldText: {
        fontWeight: 'bold',
        fontSize: '0.8vw',
    },
    creditNumberText: {
        alignSelf: 'flex-end',
        color: colors.SECONDARY,
        marginLeft: 5,
        fontSize: '0.8vw'
    },
    planContainer: {
        alignSelf: 'center',
        marginTop: 10,
        width: '100%',
    },
    planTitle: {
        color: colors.WHITE_OPACITY_40,
        fontSize: '0.8vw',
    },
    subContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    unlimitedContainer: {
        alignItems: 'center',
        backgroundColor: colors.PRIMARY,
        borderRadius: 10,
        padding: 10,
    },
    unlimitedText: {
        alignSelf: 'center',
        textAlign: 'center',
        textAlignVertical: 'center',
        fontSize: '0.8vw',
    },
}

export default UsageInfosDisplay;
