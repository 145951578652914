import React, { useEffect } from 'react';

import OnBoardingButtonList from 'screen/auth/registration/onBoarding/components/OnBoardingButtonList';
import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';

import { mpIdentify, mpTrack } from 'services/mixpanel';
import { onBoardingTranslate } from 'services/remoteConfig';

import { COACH, REGISTRATION_DATA } from 'constant/constants';
import { specializationsByUserType } from 'screen/auth/registration/onBoarding/constants/onBoardingButtonData';

function OnBoardingSpecialization() {
  mpIdentify();

  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  useEffect(() => {
    userType === COACH
      ? mpTrack(
        'Web App OnBoarding Coach Specialization Selection Screen Viewed',
      )
      : mpTrack(
        'Web App OnBoarding Professional Specialization Selection Screen Viewed',
      );
  }, []);


  return (
    <OnBoardingContainer previous={true} >
      <OnBoardingTemplate title={onBoardingTranslate('CoachSpecialisation')}>
        <div style={styles.buttonContainer}>
          <OnBoardingButtonList
            data={specializationsByUserType[userType]}
            mutlipleSelection={userType === COACH ? true : false}
            field={'specialization'}
          />
        </div>
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}
const styles = {
  buttonContainer: {
    height: '100%',
    justifyContent: 'space-evenly',
    width: '100%',
  },

};
export default OnBoardingSpecialization;
