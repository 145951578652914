import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";

import { selectHeightUnity } from "slices/unitySlice";

import { USER_LOCALE } from "services/remoteConfig";

import { ENGLISH, FT, LANGUAGE_LOOKUP } from "constant/constants";

import { colors } from "themes";

const PickerComponent = forwardRef(
  ({ config, handleValueChanges, disabled, addedStyle, type, defaultValue = null, placeholder }, ref) => {
    const heightUnity = useSelector(selectHeightUnity);

    const [value, setValue] = useState('');

    useEffect(() => {
      if (type === "Metrics") {
        setValue(heightUnity === FT ? config[0] : config[1]);
      } else if (type === "Language") {
        setValue(getLanguageValue(USER_LOCALE));
      } else {
        setValue(defaultValue);
      }
    }, [heightUnity]);

    function getLanguageValue(languageCode) {
      return LANGUAGE_LOOKUP[languageCode] || ENGLISH.value;
    }


    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
      setValue(value) {
        setValue(value);
      },
    }));

    const handleChange = (event) => {
      setValue(event?.target?.value);
      handleValueChanges(event?.target?.value);
    };

    return (
      <div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          defaultValue={defaultValue}
          variant="outlined"
          onChange={handleChange}
          sx={{ ...styles.picker, ...addedStyle }}
          disabled={disabled}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <span style={{ color: colors.WHITE_OPACITY_50 }}>{placeholder}</span>;
            }
            return selected;
          }}
        >
          {config?.map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  }
);

const styles = {
  picker: {
    color: colors.WHITE,
    backgroundColor: "#6A6A6A",
    borderRadius: "10px",
  },
};

export default PickerComponent;
