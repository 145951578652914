import React, { useEffect } from "react";

import OnBoardingContainer from "../../components/OnBoardingContainer";
import OnBoardingTemplate from "../../components/OnBoardingTemplate";
import TextComponent from "components/atoms/TextComponent";

import { onBoardingTranslate, translate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { BOLD, COACH, MEDIUM } from "constant/constants";

import { colors, images } from "themes";
import { checkUserPermission } from "utils/utils";

function ExpertiseShowCase() {

    useEffect(() => {
        checkUserPermission([COACH], true) ?
            mpTrack('Web App OnBoarding Coach Expertise ShowCase Screen Viewed')
            : mpTrack('Web App OnBoarding Professional Expertise ShowCase Screen Viewed');
    }, []);

    const Founder = ({ name, image, title }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img
                    src={image}
                    alt="testimonial"
                    style={{ height: '15vh', width: '15vh', borderRadius: '10vh' }}
                />
                <TextComponent
                    text={name}
                    weight={BOLD}
                    sx={{ color: colors.WHITE, textAlign: 'center', fontSize: '1.2rem' }}
                />
                <TextComponent
                    text={title}
                    weight={MEDIUM}
                    sx={{ color: colors.WHITE_OPACITY_60, textAlign: 'center', fontSize: '0.8rem' }}
                />
            </div>
        )

    }

    return (
        <OnBoardingContainer previous next >
            <OnBoardingTemplate
                title={onBoardingTranslate('ExpertiseShowCaseTitle')}
                text={onBoardingTranslate('ExpertiseShowCaseDesc')}
            >
                <TextComponent
                    text={translate('CreatedBy')}
                    weight={MEDIUM}
                    sx={{ color: colors.WHITE, textAlign: 'center', fontSize: '1.2rem', marginBottom: '2vh' }}
                />
                <Founder name={'Khaldon'} image={images.KHALDON_EXPERTISE} title={translate('KhaldonAthlete')} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '3vh', width: '50%', alignItems: 'center' }}>
                    <Founder name={'Victor'} image={images.VICTOR_EXPERTISE} title={onBoardingTranslate('AiExpert')} />
                    <Founder name={'Perrine'} image={images.PERRINE_EXPERTISE} title={translate('BiomecanicExpert')} />
                </div>
            </OnBoardingTemplate>
        </OnBoardingContainer >
    )

}


export default ExpertiseShowCase;