import React from "react";

import { FormControlLabel, Radio } from '@mui/material';
import { colors } from "themes";

const RadioButton = ({ value, selectedValue, addedStyle }) => (
    <FormControlLabel
        value={String(value)?.toLowerCase()}
        control={
            <Radio
                sx={{
                    color: colors.WHITE,
                    '&.Mui-checked': { color: colors.SECONDARY },
                    '&:hover': { backgroundColor: colors.SECONDARY_OPACITY_10 },
                }}
            />
        }
        label={String(value)}
        labelPlacement="end"
        sx={{
            marginLeft: '2vh',
            color: selectedValue === value ? colors.SECONDARY : colors.WHITE,
            ...addedStyle,
        }}
    />
);

export default RadioButton;