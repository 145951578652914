import React, { useState } from "react";

import { FormControl, RadioGroup } from "@mui/material";

import TextInput from "components/atoms/TextInput";
import RadioButton from "./RadioButton";
import TextComponent from "components/atoms/TextComponent";

import { BOLD } from "constant/constants";

import { colors } from "themes";
import '../styles/BackOfficeUsage.css';
import { ReactComponent as PenIcon } from 'assets/svg/pen.svg';


function AddPlanToUsage({ userUsage, modalRef, setUserInfo, setEditDb, userType }) {
    const [usage, setUsage] = useState(userUsage);
    const [errorMessage, setErrorMessage] = useState(null);
    const [monthlyCreditsSelected, setMonthlyCreditsSelected] = useState();

    function updateUsage(e, type) {
        if (type === 'subscriptionExpiration') {
            setUsage((prev) => ({
                ...prev,
                subscriptionExpiresDate: e.target.value ? new Date(e.target.value) : null,
            }));
        } else if (type === 'monthlyExpiration') {
            setUsage((prev) => ({
                ...prev,
                monthlyCreditExpired: e.target.value ? new Date(e.target.value) : null,
            }));
        } else if (type === 'EmailBillingAddress') {
            setUsage((prev) => ({
                ...prev,
                emailBillingAddress: e.target.value,
            }));
        } else if (type === 'monthlyCreditNumber') {
            console.log('monthlyCreditNumber', e.target.value);
            setMonthlyCreditsSelected(e.target.value);
            if (e.target.value === 'custom') return
            if (e.target.value === 'unlimited') {
                setUsage((prev) => ({
                    ...prev,
                    numberCreditToRenew: 0,
                    monthlyCreditNumber: 0,
                    customPlan: false,
                }));
            }
            else
                setUsage((prev) => ({
                    ...prev,
                    numberCreditToRenew: Number(e.target.value),
                    monthlyCreditNumber: Number(e.target.value),
                    customPlan: monthlyCreditsSelected !== 'custom',
                }));
        } else if (type === 'subscription') {
            const { subscriptionExpiresDate, monthlyCreditExpired } = setExpirationDates(e.target.value);
            setUsage((prev) => ({
                ...prev,
                monthlyPlan: e.target.value === 'monthly',
                yearlyPlan: e.target.value === 'yearly',
                subscriptionExpiresDate: subscriptionExpiresDate,
                monthlyCreditExpired: monthlyCreditExpired,
            }));
        } else if (type === 'customPlan') {

            setUsage((prev) => ({
                ...prev,
                numberCreditToRenew: Number(e.target.value),
                monthlyCreditNumber: Number(e.target.value),
                customPlan: true,
            }));
        }
    }

    function setExpirationDates(subscription) {
        let subscriptionExpiresDate, monthlyCreditExpired = null;
        if (subscription === 'monthly') {
            //subscriptionExpiresDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
            monthlyCreditExpired = new Date(new Date().setMonth(new Date().getMonth() + 1));
        } else if (subscription === 'yearly') {
            //  subscriptionExpiresDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            monthlyCreditExpired = new Date(new Date().setMonth(new Date().getMonth() + 1));
        }

        return { subscriptionExpiresDate, monthlyCreditExpired };
    }

    async function onValidateClick() {
        const subscriptionExpiresDate = usage?.subscriptionExpiresDate ? new Date(usage?.subscriptionExpiresDate) : null;
        const monthlyCreditExpiredDate = new Date(usage?.monthlyCreditExpired);
        const today = new Date();

        subscriptionExpiresDate?.setHours(0, 0, 0, 0);
        monthlyCreditExpiredDate?.setHours(0, 0, 0, 0);
        today?.setHours(0, 0, 0, 0);

        if (monthlyCreditExpiredDate < today) {
            setErrorMessage({
                monthlyCreditExpired: monthlyCreditExpiredDate < today ? 'Date must be greater than today' : null,
            });
            return;
        } else if (subscriptionExpiresDate && subscriptionExpiresDate < today) {
            setErrorMessage({
                subscriptionExpiresDate: subscriptionExpiresDate < today ? 'Date must be greater than today' : null,
            });
            return;
        }

        setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            usage: {
                ...prevUserInfo?.usage,
                ...usage,
                monthlyCreditExpired: usage?.customPlan === false ? null : usage?.monthlyCreditExpired,
                subscriptionExpiresDate: usage?.subscriptionExpiresDate || null,
            },
        }));
        setEditDb(true);

        modalRef.current.setClose();
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '2vh' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '2vh' }}>
                <TextComponent
                    text={'Subscription: '}
                    weight={BOLD}
                    sx={{ color: colors.WHITE, textAlign: 'center', marginBottom: '1vh' }}
                />
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="subscription"
                        name="subscription"
                        onChange={(e) => updateUsage(e, 'subscription')}
                        value={usage?.monthlyPlan ? 'monthly' : usage?.yearlyPlan ? 'yearly' : ''}
                    >
                        <RadioButton value={'Monthly'} selectedValue={usage?.monthlyPlan} addedStyle={{ color: usage?.monthlyPlan ? colors.SECONDARY : colors.WHITE }} />
                        <RadioButton value={'Yearly'} selectedValue={usage?.yearlyPlan} addedStyle={{ color: usage?.yearlyPlan ? colors.SECONDARY : colors.WHITE }} />
                    </RadioGroup>
                </FormControl>
            </div>

            <div style={{ width: '90%', display: 'flex', flexDirection: 'column', marginTop: '2vh' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '2vh' }}>
                    <TextComponent
                        text={'Monthly credit number : '}
                        weight={BOLD}
                        sx={{ color: colors.SECONDARY, marginRight: '1vh', width: '100%' }}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="subscription"
                                name="monthlyCreditNumber"
                                onChange={(e) => updateUsage(e, 'monthlyCreditNumber')}
                                row
                            >
                                <RadioButton value={Number(15)} selectedValue={monthlyCreditsSelected} />
                                <RadioButton value={Number(30)} selectedValue={monthlyCreditsSelected} />
                                <RadioButton value={Number(50)} selectedValue={monthlyCreditsSelected} />
                                <RadioButton value={'Unlimited'} selectedValue={monthlyCreditsSelected} />
                                <RadioButton value={'Custom'} selectedValue={monthlyCreditsSelected} />
                            </RadioGroup>

                        </FormControl>
                        {monthlyCreditsSelected === 'custom' && <TextInput
                            onChange={(e) => updateUsage(e, 'customPlan')}
                            type="number"
                            label={'Enter custom value'}
                        />}
                    </div>


                </div>

                {(usage?.numberCreditToRenew !== 0 || monthlyCreditsSelected === 'custom') && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '2vh' }}>
                        <TextComponent
                            text={'Monthly credit expiration : '}
                            weight={BOLD}
                            sx={{ color: colors.SECONDARY, marginRight: '1vh', width: '100%' }}
                        />

                        <TextInput
                            onChange={(e) => updateUsage(e, 'monthlyExpiration')}
                            type={'date'}
                            value={usage?.monthlyCreditExpired && new Date(usage?.monthlyCreditExpired)?.toISOString().split('T')[0]}
                            error={errorMessage?.monthlyCreditExpired}
                            helperText={errorMessage?.monthlyCreditExpired}
                        />
                    </div>
                )}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '2vh' }}>
                    <TextComponent
                        text={'Subscription expiration (optional) : '}
                        weight={BOLD}
                        sx={{ color: colors.SECONDARY, marginRight: '1vh', width: '100%' }}
                    />
                    <TextInput
                        onChange={(e) => updateUsage(e, 'subscriptionExpiration')}
                        type={'date'}
                        value={usage?.subscriptionExpiresDate && new Date(usage?.subscriptionExpiresDate)?.toISOString().split('T')[0]}
                        error={errorMessage?.subscriptionExpiresDate}
                        helperText={errorMessage?.subscriptionExpiresDate}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '2vh' }}>
                    <TextComponent
                        text={'Email billing address (optional) : '}
                        weight={BOLD}
                        sx={{ color: colors.SECONDARY, marginRight: '1vh', width: '100%' }}
                    />
                    <TextInput
                        onChange={(e) => updateUsage(e, 'EmailBillingAddress')}
                        type={'email'}
                        value={usage?.emailBillingAddress}
                    />
                </div>
            </div>

            <div className={`button button-edit`} onClick={onValidateClick} style={{ marginTop: '2vh', marginBottom: '2vh' }}>
                <TextComponent text={'Add plan'} sx={{ color: colors.WHITE }} />
                <PenIcon className="button-icon" />
            </div>
        </div>
    );
}

export default AddPlanToUsage;
