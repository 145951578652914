import React, { useEffect } from "react";

import OnBoardingContainer from "../../components/OnBoardingContainer";
import OnBoardingTemplate from "../../components/OnBoardingTemplate";

import { onBoardingTranslate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { checkUserPermission } from "utils/utils";

import { COACH } from "constant/constants";
import { LAB_ANALYSIS_TO_OCHY_COACH_URL } from "constant/onBoardingConstants";

function LabAnalysisToOchy() {

    useEffect(() => {
        checkUserPermission([COACH], true)
            ? mpTrack('Web App OnBoarding Coach Lab Analysis To Ochy Screen Viewed')
            : mpTrack('Web App OnBoarding Professional Lab Analysis To Ochy Screen Viewed');
    }, []);

    return (
        <OnBoardingContainer previous next >
            <OnBoardingTemplate
                title={
                    checkUserPermission([COACH], true)
                        ? onBoardingTranslate('LabAnalysisToOchyTitleCoach')
                        : onBoardingTranslate('LabAnalysisToOchyTitlePro')
                }
                text={
                    checkUserPermission([COACH], true)
                        ? onBoardingTranslate('LabAnalysisToOchyDescCoach')
                        : onBoardingTranslate('LabAnalysisToOchyDescPro')
                }
            >
                <video
                    src={LAB_ANALYSIS_TO_OCHY_COACH_URL}
                    alt="khaldonLabVideo"
                    loop
                    autoPlay
                    muted
                    style={{ borderRadius: 10, display: 'flex', height: '100%' }}
                />
            </OnBoardingTemplate>
        </OnBoardingContainer>
    )

}


export default LabAnalysisToOchy;