import React, { useEffect } from "react";

import TextComponent from "components/atoms/TextComponent";

import { mpTrack } from "services/mixpanel";

import { BOLD } from "constant/constants";
import { translate } from "services/remoteConfig";



function AthleteRedirectionScreen({ onBoarding = false }) {

    useEffect(() => {
        if (onBoarding) {
            mpTrack("Web App Onboarding Athlete Redirection Screen");
        } else {
            mpTrack("Web App Athlete Redirection Screen");
        }
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '92vh' }}>
            <TextComponent
                text={onBoarding ? translate('AppRedirectionOnBoarding') : translate('AppRedirection')}
                weight={BOLD}
                center={true}
                sx={{ fontSize: '2vw', textAlign: 'center', width: '80%', marginTop: '15vh' }}
            />


            <div style={rotatingBorderContainerStyle}>
                <style>{rotateAnimation}</style>
                <div style={rotatingBorderStyle}></div>
                <div style={innerContentStyle}>
                    <img
                        src={require('assets/images/QrCodeApp.png')}
                        alt="QR Code"
                        style={{ height: '100%', borderRadius: '10px' }}
                    />
                </div>
            </div>

            <TextComponent
                text={translate('ScanQrCode')}
                center={true}
                sx={{ fontSize: '1.2vw', textAlign: 'center', width: '80%', marginTop: '45vh' }}
            />

        </div>
    );
}


const rotatingBorderContainerStyle = {
    position: 'absolute',
    top: '35%',
    height: '32vh',
    width: '32vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '12px',
};

const rotatingBorderStyle = {
    position: 'absolute',
    height: '60vh',
    width: '60vh',
    borderRadius: '50%',
    background: 'linear-gradient(#ff5906, #ffffff)',
    animation: 'rotate 2s linear infinite',
};

const innerContentStyle = {
    background: '#5b5e66',
    backdropFilter: 'blur(20px)',
    height: '30vh',
    width: '30vh',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
};

const rotateAnimation = `
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default AthleteRedirectionScreen;
