import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";

import TextComponent from "components/atoms/TextComponent";
import HeightComponent from "components/atoms/HeightComponent";
import WeightComponent from "components/atoms/WeightComponent";
import AthleteSubscription from "./components/AthleteSubscription";
import SidePan from "components/molecules/SidePan";
import SettingsSelector from "./settings/SettingsSelector";

import { updateUserInDB } from "services/authApi";
import { uploadFile } from "services/upload";
import { translate } from "services/remoteConfig";

import { userProfilePic, checkUserPermission, getURLFile } from "utils/utils";

import { colors } from "themes";

import { ReactComponent as SettingsIcon } from "assets/svg/settings.svg";
import { ReactComponent as EditAvatar } from "assets/svg/editAvatar.svg";
import CoachSubscription from "./components/CoachSubscription";
import { ATHLETE } from "constant/constants";


function PersonnalInfos({ userSlice }) {
    const sidePanRef = useRef();
    const [selected, setSelected] = useState(null);
    const usageStore = useSelector((state) => state?.usage);

    const handlePress = (elementName) => {
        setSelected(elementName);
        sidePanRef?.current?.open();
    };

    async function updateProfilePic(e) {
        const url = getURLFile(e);
        const dbURL = await uploadFile(url, undefined, undefined, undefined, 'profile_picture', 'image', userSlice?.userID);
        if (dbURL) {
            updateUserInDB({ photo: dbURL });
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.avatarContainer} onClick={() => document.getElementById('avatar').click()}>
                <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg, image/svg" style={styles.imageInput} onChange={updateProfilePic} />
                <img src={userProfilePic(userSlice?.photo, userSlice?.gender)} alt="profile" style={styles.avatar} />
                <EditAvatar height={'3vh'} width={'3vh'} />
            </div>
            <TextComponent text={userSlice?.fullName} sx={styles.fullName} />
            <TextComponent text={userSlice?.emailAddress} sx={styles.emailAddress} />
            {checkUserPermission([ATHLETE]) && (
                <div style={styles.measurementContainer}>
                    <HeightComponent height={userSlice?.height} />
                    <WeightComponent weight={userSlice?.weight} />
                </div>
            )}
            <div style={styles.editProfile} onClick={() => handlePress('EditProfil')}>
                <TextComponent text={translate('Edit')} sx={styles.editProfileText} />
            </div>
            {checkUserPermission([ATHLETE]) ? <AthleteSubscription item={usageStore} onClick={(elementName) => handlePress(elementName)} />
                : <CoachSubscription item={usageStore} onClick={(elementName) => handlePress(elementName)} />
            }
            <div style={styles.settingsButton} onClick={() => handlePress('Settings')}>
                <SettingsIcon height={'3vh'} width={'3vh'} fill={colors.LIGHT_GRAY} />
                <TextComponent text={translate('SettingsTitle')} sx={styles.settingsText} />
            </div>
            <SidePan ref={sidePanRef} onButtonPressed={() => sidePanRef?.current?.close()} fullWidth={false}>
                <div style={styles.sidePanContent}>
                    <SettingsSelector name={selected} onClick={(elementName) => handlePress(elementName)} sidePanRef={sidePanRef} />
                </div>
            </SidePan>
        </div>
    );
}

const styles = {
    container: {
        width: '30%',
        backgroundColor: 'black',
        borderRadius: 10,
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex'
    },
    avatarContainer: {
        cursor: 'pointer'
    },
    imageInput: {
        display: 'none'
    },
    avatar: {
        width: '15vh',
        height: '15vh',
        borderRadius: '8vh',
        marginTop: '4vh',
        objectFit: 'cover'
    },
    fullName: {
        marginTop: '1vh',
        fontSize: '1.2vw'
    },
    emailAddress: {
        color: colors.TEXT_GRAY,
        fontSize: '0.8vw'
    },
    measurementContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '1vh'
    },
    editProfile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '1vh',
        cursor: 'pointer'
    },
    editProfileText: {
        marginTop: '1vh',
        textDecoration: 'underline',
        fontSize: '0.8vw'
    },
    settingsButton: {
        display: 'flex',
        backgroundColor: colors.WHITE_OPACITY_20,
        borderRadius: '1vh',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1vh',
        cursor: 'pointer',
        position: 'absolute',
        bottom: '6vh',
    },
    settingsText: {
        color: colors.LIGHT_GRAY,
        fontSize: '1.5vh',
        marginLeft: '0.5vw'
    },
    sidePanContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20%"
    }
};

export default PersonnalInfos;
