import React, { useContext } from "react";

import TextComponent from "components/atoms/TextComponent";
import ProgressList from "./ProgressList";

import { RegistrationContext } from "contexts/RegistrationContext";

import { createTextObjectReusable } from "utils/utils";

import { BOLD, H4, MEDIUM } from "constant/constants";

import { colors } from "themes";

function OnBoardingTemplate({ title, text, children, titleOnly = false }) {
    const { currentIndex } = useContext(RegistrationContext);

    return (
        <div style={styles.container}>
            {currentIndex > 2 && <ProgressList />}
            <div style={{ display: 'flex', marginTop: titleOnly ? '0vh' : '4vh' }} />
            <TextComponent
                multipleText={createTextObjectReusable(
                    title,
                    {
                        variant: H4,
                        weight: titleOnly ? MEDIUM : BOLD,
                    },
                    {
                        variant: H4,
                        weight: BOLD,
                        style: { textAlign: 'center', color: colors.SECONDARY },
                    },
                    {
                        variant: H4,
                        weight: BOLD,
                        style: { textAlign: 'center', color: colors.WHITE },
                    },
                )}
                center={true}
            />
            {!titleOnly && <div style={{
                flex: 1,
                minHeight: '40vh',
                width: '100%',
                borderRadius: 10,
                padding: '3vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {children}
            </div>}

            <TextComponent
                text={text}
                sx={{ color: colors.WHITE, textAlign: 'center' }}
            />
        </div >
    )

}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
}


export default OnBoardingTemplate;