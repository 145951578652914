import React from 'react';

import { useSelector } from 'react-redux';

import ButtonComponent from 'components/atoms/ButtonComponent';
import TextComponent from 'components/atoms/TextComponent';
import StatusTag from './StatusTag';
import CreditsRecap from './CreditsRecap';
import UnlimitedAnalysisButton from './UnlimitedAnalysisButton';

import { translate } from 'services/remoteConfig';

import { checkUserPermission } from 'utils/utils';

import { ATHLETE, BOLD, COACH, ORANGE, PROFESSIONAL } from 'constant/constants';

import { colors } from 'themes';

function AthleteSubscription({ item, onClick }) {
  const userSlice = useSelector(state => state?.user);
  const userType =
    checkUserPermission([ATHLETE]) || userSlice?.typology === 'PersonnalCoach';

  function displaySubscription() {
    if (!item?.monthlyPlan && !item?.yearlyPlan && !item?.quaterlyPlan) {
      return <></>;
    } else
      return (
        <>
          <div style={styles.subContainer}>
            <TextComponent
              text={
                item?.yearlyPlan
                  ? translate('YearlyPlan') :
                  item?.quaterlyPlan ? translate('QuaterlyPlan')
                    : translate('MonthlyPlan')
              }
              weight={BOLD}
            />
            <StatusTag />
          </div>
          <div style={{ marginTop: '1vh', marginBottom: '1vh', }}>
            <UnlimitedAnalysisButton />
          </div>
        </>
      );
  }

  return (
    <>
      <div style={styles.container}>
        <div style={styles.creditContainer}>
          {displaySubscription()}
          <div style={styles.CTAContainer}>
            {!item?.monthlyPlan && !item?.yearlyPlan && !item?.quaterlyPlan ? (
              <div style={styles.creditNumberContainer}>
                <TextComponent
                  sx={item?.creditNumber ? styles.creditNumber : styles.zero}
                  text={item?.creditNumber ? item?.creditNumber : 0}
                />
              </div>
            ) : null}
            {!item?.yearlyPlan && (
              <div style={styles.buttonCreditContainer}>
                <ButtonComponent
                  type={ORANGE}
                  title={
                    item?.monthlyPlan || item?.yearlyPlan
                      ? translate('UpgradeMyPlan')
                      : translate('GetMoreAnalysis')
                  }
                  onClick={() => {
                    userType
                      ? onClick('GetMoreAnalysis')
                      : onClick('ContactSales');
                  }}
                  sx={styles.button}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  CTAContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 8,
  },
  button: {
    fontSize: '0.8vw',
    padding: '1vh',
  },
  buttonCreditContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    marginBottom: '3vh',
    width: '90%',
  },
  creditContainer: {
    backgroundColor: colors?.WHITE_OPACITY_04,
    borderRadius: 20,
    marginTop: '5%',
  },
  creditNumber: {
    color: colors.SECONDARY,
    fontSize: '2.5vw',
  },
  creditNumberContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  planContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: 5,
  },
  subContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
  },

  zero: {
    color: colors.WHITE_OPACITY_40,
    fontSize: '2.5vw',
    textAlign: 'center',
  },
};

export default AthleteSubscription;
