import React from "react";

import AppLogo from "./components/AppLogo";
import NavigationPanelButton from "components/atoms/NavigationPanelButton";

import {
  MENU_CONSTANT_ATHLETE,
  MENU_CONSTANT_COACH,
  MENU_CONSTANT_COACH_LEADERBOARD,
  MENU_CONSTANT_PRO,
} from "constant/menuConstant";
import { ATHLETE } from "constant/constants";

import { checkUserPermission, isPro } from "utils/utils";

import { colors } from "themes";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function NavigationPanel() {
  const userSlice = useSelector((state) => state?.user);
  const CONSTANT = checkUserPermission([ATHLETE])
    ? MENU_CONSTANT_ATHLETE
    : isPro()
      ? MENU_CONSTANT_PRO :
      userSlice?.emailAddress?.endsWith('@ochy.io') && userSlice?.admin === true ? MENU_CONSTANT_COACH_LEADERBOARD
        : userSlice?.emailAddress?.endsWith('@ochy.io') ? MENU_CONSTANT_COACH_LEADERBOARD.slice(0, -1)
          : MENU_CONSTANT_COACH;

  const navigation = useLocation();

  if (navigation?.pathname === "/leaderboard") return null;

  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <AppLogo />
      </div>
      <div style={styles.buttonContainer}>
        {!checkUserPermission([ATHLETE]) && CONSTANT.map((item) => {
          return (
            <NavigationPanelButton
              key={item?.id}
              id={item?.id}
              icon={item?.icon}
              path={item?.path}
              title={item?.name}
            />
          );
        })}
      </div>
    </div>
  );
}
const styles = {
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "30%",
    width: "100%",
  },
  container: {
    justifyContent: "center",
    backgroundColor: colors.BACKGROUND,
    borderRadius: 10,
    minHeight: "99%",
    position: "fixed",
    width: "12%",
    zIndex: 1000,
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    height: "80px",
    justifyContent: "center",
    width: "100%",
  },
};

export default NavigationPanel;
