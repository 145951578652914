export const COMMON_STACK = [
  {path: '/registration'},
  {path: '/registration/ochy-presentation'},
  {path: '/registration/select-user-type'},
];

export const ATHLETE_STACK = [
  {path: '/registration/athlete/app-redirection'},
  // {path: '/registration/athlete/welcome'},
  // {path: '/registration/athlete/typology'},
  // {path: '/registration/athlete/typology-welcome'},
  // {path: '/registration/athlete/personnal-text'},
  // {path: '/registration/athlete/gender'},
  // {path: '/registration/athlete/running-level'},
  // {path: '/registration/athlete/running-frequency'},
  // {path: '/registration/athlete/personnalised-analysis'},
  // {path: '/registration/athlete/height-weight'},
  // {path: '/registration/athlete/analysis-introduction'},
  // {path: '/registration/athlete/analyse'},
  // {path: '/registration/athlete/train'},
  // {path: '/registration/athlete/compare'},
  // {path: '/registration/athlete/progress'},
  // {path: '/registration/athlete/profile-creation'},
];

export const COACH_STACK = [
  {path: '/registration/coach/welcome'},
  {path: '/registration/coach/specialization'},
  {path: '/registration/coach/place-of-work'},
  {path: '/registration/coach/expertise-show-case'},
  {path: '/registration/coach/laboratory-to-ochy'},
  {path: '/registration/coach/analysis-accessibility'},
  {path: '/registration/coach/full-body-analysis'},
  {path: '/registration/coach/analysis-highlights'},
  {path: '/registration/coach/data-driven-training'},
  {path: '/registration/coach/science-driven-analysis'},
  {path: '/registration/coach/testimonials'},
  {path: '/registration/coach/video-tuto-intro'},
  {path: '/registration/coach/video-tuto'},
  {path: '/registration/coach/profile-creation'},
];

export const PROFESSIONAL_STACK = [
  {path: '/registration/professional/specialization'},
  {path: '/registration/professional/place-of-work'},
  {path: '/registration/professional/expertise-show-case'},
  {path: '/registration/professional/laboratory-to-ochy'},
  {path: '/registration/professional/analysis-accessibility'},
  {path: '/registration/professional/full-body-analysis'},
  {path: '/registration/professional/analysis-highlights'},
  {path: '/registration/professional/data-driven'},
  {path: '/registration/professional/science-driven-analysis'},
  {path: '/registration/professional/testimonials'},
  {path: '/registration/professional/video-tuto-intro'},
  {path: '/registration/professional/video-tuto'},
  {path: '/registration/professional/profile-creation'},
];

export const ANALYSIS_ACCESSIBILITY_COACH_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2FanalysisAccessibilityCoachHorizontal.mp4?alt=media';

export const ANALYSIS_ACCESSIBILITY_PRO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2FanalysisAccessibilityProHorizontal.mp4?alt=media';

export const LAB_ANALYSIS_TO_OCHY_COACH_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2FkhaldonLabVideoHorizontal.mp4?alt=media';
