import ErrorPage from "Error-page";
import React from "react";
import { Route, Routes } from "react-router-dom";
import OnBoardingSpecialization from "screen/auth/registration/onBoarding/coach/OnBoardingSpecialization";
import OnBoardingFinished from "screen/auth/registration/onBoarding/common/OnBoardingFinished";
import AnalysisAccessibility from "screen/auth/registration/onBoarding/common/userTypeSpecific/AnalysisAccessibility";
import ExpertiseShowCase from "screen/auth/registration/onBoarding/common/userTypeSpecific/ExpertiseShowCase";
import FullPictureAnalysis from "screen/auth/registration/onBoarding/common/userTypeSpecific/FullPictureAnalysis";
import LabAnalysisToOchy from "screen/auth/registration/onBoarding/common/userTypeSpecific/LabAnalysisToOchy";
import QuickAnalysisHighlight from "screen/auth/registration/onBoarding/common/userTypeSpecific/QuickAnalysisHighlight";
import TestimonialHighlight from "screen/auth/registration/onBoarding/common/userTypeSpecific/TestimonialHighlight";
import VideoTutoIntro from "screen/auth/registration/onBoarding/common/userTypeSpecific/VideoTutoIntro";
import DataDrivenApproach from "screen/auth/registration/onBoarding/professional/DataDrivenApproach";
import PlaceOfWork from "screen/auth/registration/onBoarding/common/userTypeSpecific/PlaceOfWork";
import OnBoardingGoodVideo from "screen/auth/registration/onBoarding/common/userTypeSpecific/OnBoardingGoodVideo";
import ScienceDrivenAnalysis from "screen/auth/registration/onBoarding/common/userTypeSpecific/ScienceDrivenAnalysis";


function ProfessionalStack() {
    return (
        <Routes>
            <Route
                path="/specialization"
                element={<OnBoardingSpecialization />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/place-of-work"
                element={<PlaceOfWork />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/expertise-show-case"
                element={<ExpertiseShowCase />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/laboratory-to-ochy"
                element={<LabAnalysisToOchy />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/analysis-accessibility"
                element={<AnalysisAccessibility />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/full-body-analysis"
                element={<FullPictureAnalysis />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/analysis-highlights"
                element={<QuickAnalysisHighlight />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/data-driven"
                element={<DataDrivenApproach />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/science-driven-analysis"
                element={<ScienceDrivenAnalysis />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/testimonials"
                element={<TestimonialHighlight />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/video-tuto-intro"
                element={<VideoTutoIntro />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/video-tuto"
                element={<OnBoardingGoodVideo />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/profile-creation"
                element={<OnBoardingFinished />}
                errorElement={<ErrorPage />}
            />
        </Routes>
    )
}

export default ProfessionalStack;