import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'lottie-react';

import TextComponent from 'components/atoms/TextComponent';
import OnBoardingContainer from '../components/OnBoardingContainer';

import { onBoardingTranslate } from 'services/remoteConfig';

import { useUsageChanges } from 'hooks/useUsageChanges';
import { useProfileChanges } from 'hooks/useProfileChanges';

import { selectUser, setUserType } from 'slices/userSlice';
import { mpTrack } from 'services/mixpanel';

import { createTextObjectReusable } from 'utils/utils';

import { ATHLETE, BOLD, H1, H3, REGISTRATION_DATA } from 'constant/constants';

import { colors } from 'themes';

import wavingHand from 'assets/animations/wavingHand.json';

function OnBoardingWelcome() {
  useUsageChanges();
  useProfileChanges();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    mpTrack('Web App OnBoarding Welcome Screen Viewed');
    localStorage.removeItem(REGISTRATION_DATA);
    if (user?.userType === 'Unknown' || !user?.userType) {
      dispatch(setUserType(ATHLETE));
    }
  }, []);

  return (
    <OnBoardingContainer next>
      <div style={{ alignSelf: 'center' }} >
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('WelcomeToOchy'),
            {
              variant: H1,
              weight: BOLD,
            },
            {
              variant: H1,
              weight: BOLD,
              style: { textAlign: 'center', color: colors.SECONDARY },
            },
          )}
          variant={H3}
          weight={BOLD}
          center={true}
        />
        <div style={{ width: '20%', margin: 'auto' }}>
          <Lottie animationData={wavingHand} />
        </div>
      </div>
    </OnBoardingContainer>
  );
}

const styles = {
  title: {
    textAlign: 'center',
  },
};

export default OnBoardingWelcome;
