import React, { useState } from 'react';

import { logInWithEmailAndPassword } from 'services/authApi';
import { translate } from 'services/remoteConfig';

import { mpTrack } from 'services/mixpanel';
import ButtonComponent from 'components/atoms/ButtonComponent';
import './styles/Login.css';
import TextComponent from 'components/atoms/TextComponent';
import { BODY_2, ORANGE } from 'constant/constants';

import { useNavigate } from 'react-router-dom';
import TextInput from 'components/atoms/TextInput';
import ClickableTextComponent from 'components/atoms/ClickableTextComponent';
import AuthContainer from './components/AuthContainer';
import { colors } from 'themes';
import SsoButtons from './components/SsoButtons';

const PASSWORD = 'password';
const yup = require('yup');

function Login() {
  const userSchema = yup.object({
    email: yup
      .string()
      .email(translate('AuthErrorInvalidEmail'))
      .required(translate('EmailRequired')),
    password: yup.string().required(translate('PasswordRequired')),
  });

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();

  function handleLoginSuccessful() {
    navigate('/');
  }

  const handleSubmit = async e => {
    mpTrack('Web App Login Button Clicked');

    if (e) e.preventDefault();
    try {
      await userSchema?.validate(formValues, { abortEarly: false });
      await logInWithEmailAndPassword(
        formValues?.email,
        formValues?.password,
        handleLoginSuccessful,
        onLoginFailed,
      );
    } catch (err) {
      if (err.inner) {
        const errors = {};
        err.inner.forEach(error => {
          errors[error.path] = error.message;
        });
        setFormErrors(errors);
      }
    }
  };

  async function onLoginFailed(errorEmail, errorPassword) {
    setFormErrors({
      email: translate(errorEmail),
      password: translate(errorPassword),
    });
  }
  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    if (formErrors[e.target.name]) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: '',
      });
    }
  };

  return (
    <AuthContainer>
      <TextComponent
        text={translate('Welcome')}
        variant={'h2'}
        sx={styles.textCenter}
      />
      <TextComponent
        text={translate('RegisterSubTitle')}
        variant={'body1'}
        sx={{ ...styles.textCenter, marginTop: '3%', width: '80%' }}
        weight={'400'}
      />
      <div style={styles.width80}>
        <div style={styles.marginTop10}>
          <TextInput
            name="email"
            label={translate('EmailAdress')}
            value={formValues?.email}
            onChange={handleChange}
            schema={userSchema}
            error={!!formErrors?.email}
            helperText={formErrors?.email}
            title={translate('EmailAdress')}
            handleSubmit={() => {
              document.getElementById('passwordTextInput').focus();
            }}
            image={require('assets/images/mail.png')}
          />
        </div>
        <div style={styles.margin6}>
          <TextInput
            title={translate('Password')}
            id="passwordTextInput"
            name={PASSWORD}
            label={translate('Password')}
            type={PASSWORD}
            value={formValues?.password}
            onChange={handleChange}
            schema={userSchema}
            error={!!formErrors?.password}
            helperText={formErrors?.password}
            focusedImage={require('assets/images/view.png')}
            image={require('assets/images/hide.png')}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
      <div style={styles.marginTop7}>
        <ButtonComponent
          type={ORANGE}
          title={translate('Login')}
          onClick={handleSubmit}
          sx={{
            width: '60%',
            height: '3rem',
            borderRadius: '20rem',
            fontSize: '1rem',
            fontWeight: '600',
          }}
        />
      </div>
      <div style={styles.clickTextContainer}>
        <ClickableTextComponent
          title={translate('ForgotPassword')}
          style={{ float: 'left' }}
          onPress={() => {
            mpTrack('Web App Forgot Password Clicked');
            navigate('/forgotPassword');
          }}
          option={{ weight: 'bold' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '80%',
          flexDirection: 'column',
        }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 30,
          }}>
          <div
            style={{
              height: 2,
              width: '98%',
              marginRight: 10,
              backgroundColor: colors?.WHITE_OPACITY_10,
            }}
          />
          <TextComponent text={translate('Or')} />
          <div
            style={{
              height: 2,
              width: '100%',
              marginLeft: 10,
              backgroundColor: colors?.WHITE_OPACITY_10,
            }}
          />
        </div>
        <SsoButtons />
        <div style={{ display: 'flex', marginTop: 20 }}>
          <TextComponent text={translate('NoAccount')} variant={BODY_2} />
          <ClickableTextComponent
            title={translate('SignUp')}
            onPress={() => navigate('/register')}
            variant={BODY_2}
            sx={{ marginLeft: 1 }}
          />
        </div>
      </div>
    </AuthContainer>
  );
}

const styles = {
  buttonSSO: {
    marginTop: '5%',
    width: '60%',
    height: '3rem',
    borderRadius: '20rem',
    fontSize: '1rem',
    fontWeight: '600',
    color: 'black',
    textTransform: 'none',
    backgroundColor: '#E9E9E9',
  },
  clickTextContainer: {
    textAlign: 'center',
    marginTop: '2%',
    width: '80%',
  },
  noAccountContainer: {
    height: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  margin6: {
    marginTop: '6%',
  },
  marginTop7: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',

    marginTop: '10%',
  },
  marginTop10: {
    marginTop: '10%',
  },
  textCenter: {
    textAlign: 'center',
  },
  width80: {
    width: '80%',
  },
};
export default Login;
