import React, { useEffect } from "react";

import OnBoardingContainer from "../../components/OnBoardingContainer";
import OnBoardingTemplate from "../../components/OnBoardingTemplate";
import TextComponent from "components/atoms/TextComponent";

import { onBoardingTranslate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { checkUserPermission } from "utils/utils";

import { COACH } from "constant/constants";

import { images } from "themes";

function FullPictureAnalysis() {

    useEffect(() => {
        checkUserPermission([COACH], true)
            ? mpTrack('Web App OnBoarding Coach Full Picture Analysis Screen Viewed')
            : mpTrack('Web App OnBoarding Professional Full Picture Analysis Screen Viewed');
    }, []);

    return (
        <OnBoardingContainer previous next >
            <OnBoardingTemplate
                title={checkUserPermission([COACH], true)
                    ? onBoardingTranslate('FullPictureTitleCoach')
                    : onBoardingTranslate('FullPictureTitlePro')}
                text={checkUserPermission([COACH], true)
                    ? onBoardingTranslate('FullPictureDescCoach')
                    : onBoardingTranslate('FullPictureDescPro')}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                    <img
                        src={images.ANALYSIS_FULL_PICTURE}
                        alt="science-based-analysis"
                        style={{ height: '100%', width: '100%', objectFit: 'contain', }}
                    />
                    <TextComponent
                        text={onBoardingTranslate('HeadTilt')}
                        sx={{ position: 'absolute', top: '25%', left: '56%', fontSize: '1vw' }}
                    />
                    <TextComponent
                        text={onBoardingTranslate('LowBackCycle')}
                        sx={{ position: 'absolute', top: '42%', right: '58%', fontSize: '1vw' }}
                    />
                    <TextComponent
                        text={onBoardingTranslate('FlatFootLanding')}
                        sx={{ position: 'absolute', top: '72%', right: '49%', fontSize: '1vw' }}
                    />
                    <TextComponent
                        text={onBoardingTranslate('Pronated')}
                        sx={{ position: 'absolute', bottom: '32%', left: '59%', fontSize: '1vw' }}
                    />
                </div>
            </OnBoardingTemplate>
        </OnBoardingContainer>
    )
}

export default FullPictureAnalysis;