import React from 'react';
import { animated, useSpring } from 'react-spring';

function FadinView({ children, style }) {
  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });

  return <animated.div style={{ ...styles, ...style }}>
    {children}
  </animated.div>;
}

export default FadinView;
