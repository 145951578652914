import React, { useEffect } from "react";

import OnBoardingContainer from "../../components/OnBoardingContainer";
import OnBoardingTemplate from "../../components/OnBoardingTemplate";

import { onBoardingTranslate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { checkUserPermission } from "utils/utils";

import { COACH } from "constant/constants";

import { images } from "themes";

function ScienceDrivenAnalysis() {

    useEffect(() => {
        checkUserPermission([COACH], true)
            ? mpTrack('Web App OnBoarding Coach Science Driven Analysis Screen Viewed')
            : mpTrack('Web App OnBoarding Professional Science Driven Analysis Screen Viewed');
    }, []);

    return (
        <OnBoardingContainer previous next>
            <OnBoardingTemplate
                title={onBoardingTranslate('ScienceDrivenAnalysisTitle')}
                text={onBoardingTranslate('ScienceDrivenAnalysisDesc')}
            >
                <img
                    src={images.SCIENCE_DRIVEN_ANALYSIS}
                    alt="science-driven-analysis"
                    style={{ height: '100%', width: '100%', objectFit: 'contain', }}
                />
            </OnBoardingTemplate>
        </OnBoardingContainer>
    )
}

export default ScienceDrivenAnalysis;