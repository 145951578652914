import React, { useContext } from 'react';

import { RegistrationContext } from 'contexts/RegistrationContext';

import { colors } from 'themes';

const ProgressList = () => {
    const { combinedStack, currentIndex } = useContext(RegistrationContext);

    const numberOfCircles = combinedStack?.length - 3;
    const circles = Array.from({ length: numberOfCircles });
    const currentStep = currentIndex - 3;

    const renderItem = (index) => {
        return (
            <div
                style={{
                    ...styles.glowContainer,
                    marginLeft: index === 0 ? '20px' : '0.5px',
                    marginRight: index === numberOfCircles - 1 ? '20px' : '0.5px',
                    boxShadow:
                        index === currentStep
                            ? `0 0 10px ${colors.SECONDARY}`
                            : index < currentStep
                                ? `0 0 10px ${colors.GREEN}`
                                : 'none',
                }}
            >
                <div
                    style={{
                        ...styles.dot,
                        backgroundColor:
                            index === currentStep
                                ? colors.SECONDARY
                                : index < currentStep
                                    ? colors.GREEN
                                    : colors.WHITE_OPACITY_40,
                        ...(index === currentStep && styles.line),
                    }}
                />
            </div>
        );
    };

    return (
        <div style={styles.container}>
            {circles?.map((_, index) => renderItem(index))}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        marginTop: '4vh'
    },
    dot: {
        borderRadius: '10px',
        height: '1vh',
        width: '1vh',
    },
    glowContainer: {
        borderRadius: '20px',
        padding: '3px',
        margin: '0 3px',
    },
    line: {
        borderRadius: '10px',
        borderWidth: '2px',
        height: '1vh',
        width: '3vw',
    },
};



export default ProgressList;
