import React from 'react';

import TextComponent from 'components/atoms/TextComponent';

import { translate } from 'services/remoteConfig';

import { ReactComponent as Arrow } from 'assets/svg/arrow-right-bold.svg';

import { colors } from 'themes';

function OnBoardingBottomArrow({ onPress = null, previous = false, analysis = false }) {

  function handleStyle() {
    if (analysis) {
      return styles.containerNextArrowAnalysis;
    }
    if (previous) {
      return styles.containerPreviousArrow;
    } else {
      return styles.containerNextArrow;
    }
  }
  return (
    <div
      style={{ ...handleStyle(), ...styles.container }}
      onClick={onPress}>
      {!previous && <TextComponent
        text={translate('Next')}
        sx={{ fontSize: '1.5vw' }}
      />}
      <Arrow
        color={colors.WHITE}
        height={'3vw'}
        width={'3vw'}
        strokeWidth={5}
        stroke={colors.WHITE}
        style={{ transform: previous ? 'rotate(180deg)' : '' }}
      />
      {previous && <TextComponent
        text={translate('GoBack')}
        sx={{ fontSize: '1.5vw' }}
      />}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  containerNextArrowAnalysis: {
    bottom: 20,
    position: 'absolute',
    right: '30%',
  },
  containerNextArrow: {
    bottom: 20,
    position: 'absolute',
    right: '20%',
  },
  containerPreviousArrow: {
    bottom: 20,
    position: 'absolute',
    left: '20%',
  },
};

export default OnBoardingBottomArrow;
