import React, { useEffect, useState } from "react";

import getUserLocale from "get-user-locale";

import PickerComponent from "components/atoms/PickerComponent";
import TextComponent from "components/atoms/TextComponent";
import TextInput from "components/atoms/TextInput";
import PickerPhoneComponent from "components/molecules/PickerPhoneComponent";
import ButtonComponent from "components/atoms/ButtonComponent";

import { translate } from "services/remoteConfig";

import { BODY_2, BOLD, H5, ORANGE } from "constant/constants";
import { FORM_INPUTS } from "constant/contactSalesConstants";

import { colors } from "themes";

export default function ContactSalesForm({ step, formValues, setFormValues, errorMessage, setErrorMessage, big, onFinish }) {
    const [roleText, setRoleText] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');


    function handleInputsChange(e, input, type = 'text') {
        const updatedInputs = { ...formValues, [input]: type === 'multiSelect' ? e : e?.target?.value };
        setFormValues(updatedInputs);
        setErrorMessage(null);

    }

    useEffect(() => {
        setFormValues((prev) => ({ ...prev, phoneNumber: phoneNumber }));
    }, [phoneNumber]);


    const renderInput = (input, index) => {

        if (input.id === 'phoneNumber') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextComponent variant={BODY_2} weight={BOLD} text={translate(input?.title)} sx={{ marginTop: 2, marginBottom: 1 }} />
                    <div style={styles.phoneRow}>
                        <PickerPhoneComponent height="2rem" defaultCountryCode={getUserLocale()?.split('-')[1]} setDialCode={setPhoneNumber} />
                        <div style={{ flexDirection: 'column', width: '100%', marginLeft: '1vw' }}>
                            <TextInput
                                label={translate(input.title)}
                                id={input.id}
                                value={formValues[input.id]}
                                onChange={(e) => handleInputsChange(e, input.id)}
                                handleSubmit={() => document.getElementById(FORM_INPUTS[index + 1]?.id).focus()}
                                type="tel"
                                inputHeight={'2rem'}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        if (input?.type === 'multiselect') {
            return (<div style={styles.multiselectContainer}>
                <div>
                    <TextComponent variant={BODY_2} weight={BOLD} text={translate(input?.title)} sx={styles.pickerTitle} />
                    <PickerComponent
                        config={input?.choices?.map(item => translate(item?.title))}
                        addedStyle={styles.pickerComponent}
                        handleValueChanges={(value) => {
                            handleInputsChange(value, 'role', 'multiSelect')
                            setRoleText(value)
                        }}
                        placeholder={translate(input?.title)}
                    />
                </div>


                {roleText && (
                    <div>
                        <TextComponent variant={BODY_2} weight={BOLD} text={translate('JobTitle')} sx={styles.pickerTitle} />
                        <PickerComponent
                            config={input?.choices
                                ?.find(item => translate(item?.title) === roleText)
                                ?.job?.map(item => translate(item))}
                            addedStyle={styles.pickerComponent}
                            handleValueChanges={(value) => handleInputsChange(value, 'jobTitle', 'multiSelect')}
                            placeholder={translate('JobTitle')}
                        />
                    </div>
                )
                }
                {roleText && formValues['jobTitle'] === translate('Other') && (
                    <TextInput
                        label={translate('Other')}
                        id={input.id}
                        onChange={(e) => handleInputsChange(e, 'jobTitleOther')}
                        title={translate('Other')}
                        type={'text'}
                        inputHeight={'2rem'}
                        value={formValues['jobTitleOther']}
                    />
                )}
            </div>)

        } else
            return (
                <TextInput
                    label={input?.type === 'number' ? '0' : translate(input?.title)}
                    id={input.id}
                    onChange={(e) => handleInputsChange(e, input.id)}
                    title={translate(input.title)}
                    type={input.type}
                    handleSubmit={() => {
                        FORM_INPUTS[index + 1] && document.getElementById(FORM_INPUTS[index + 1]?.id).focus();
                    }}
                    inputHeight={'2rem'}
                    value={formValues[input.id]}
                />
            );
    };


    return (
        <div style={{ ...styles.container, ...{ width: !big ? '20vw' : '30vw' } }}>
            {step < FORM_INPUTS?.length ? (
                <div style={styles.inputsContainer}>
                    <TextComponent
                        text={translate(FORM_INPUTS[step]?.section_title)}
                        sx={{ marginTop: '2vh', textAlign: 'center', fontSize: '1.4vw' }}
                    />

                    {FORM_INPUTS?.map((input, index) => {
                        if (index === step) {
                            return input?.components?.map((component, index) => {
                                return renderInput(component, index);
                            });
                        }
                        return null;
                    })}
                    {errorMessage && (
                        <TextComponent
                            sx={styles.error}
                            text={translate(errorMessage)}
                            variant={BODY_2}
                        />
                    )}
                </div>
            ) : (
                <div
                    style={styles.finishedContainer}>
                    <TextComponent
                        text={translate('RequestReceived')}
                        variant={H5}
                        style={{ textAlign: 'center' }}
                    />

                    <ButtonComponent
                        title={translate('Finish')}
                        type={ORANGE}
                        onClick={onFinish}
                        sx={{ marginTop: '4vh' }}
                    />
                </div>
            )
            }

        </div >
    )
}

const styles = {
    container: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: '2vh' },
    error: {
        color: 'red',
        textAlign: 'center',
        marginTop: '3vh',
    },
    finishedContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 100,
        display: 'flex',
        flexDirection: 'column',
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    multiselectContainer: { display: 'flex', flexDirection: 'column', width: '100%' },
    pickerComponent: {
        height: '2rem', width: '100%', backgroundColor: colors.TRANSPARENT, "&:hover": {
            backgroundColor: "rgb(255,255,255, 0.17)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
        },
        color: 'white'
    },
    pickerTitle: {
        marginBottom: 1,
        marginTop: 2,
    },
    phoneRow: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
}