import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  creditNumber: 0,
  nTrial: 0,
  monthlyCreditNumber: 0,
  yearlyPlan: false,
  monthlyPlan: false,
  monthlyCreditExpired: '',
  freeTrialTimeStamp: '',
  customPlan: false,
  numberCreditToRenew: 0,
};

export const usageSlice = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    resetUsageStore: (state, action) => {
      state.creditNumber = 0;
      state.nTrial = 0;
      state.monthlyCreditNumber = 0;
      state.monthlyPlan = false;
      state.yearlyPlan = false;
      state.monthlyCreditExpired = '';
      state.freeTrialTimeStamp = '';
      state.customPlan = false;
      state.numberCreditToRenew = 0;
    },

    setCreditNumber: (state, action) => {
      state.creditNumber = action?.payload;
    },
    setNTrial: (state, action) => {
      state.nTrial = action?.payload;
    },
    setMonthlyCreditNumber: (state, action) => {
      state.monthlyCreditNumber = action?.payload;
    },
    setYearlyPlan: (state, action) => {
      state.yearlyPlan = action?.payload;
    },
    setMonthlyPlan: (state, action) => {
      state.monthlyPlan = action?.payload;
    },
    updateUsageStore: (state, action) => {
      state.creditNumber = action?.payload?.creditNumber || 0;
      state.nTrial = action?.payload?.trial || 0;
      state.monthlyCreditNumber = action?.payload?.monthlyCreditNumber || 0;
      state.yearlyPlan = action?.payload?.yearlyPlan || false;
      state.monthlyPlan = action?.payload?.monthlyPlan || false;
      state.monthlyCreditExpired =
        String(
          new Date(
            action?.payload?.monthlyCreditExpired?.seconds * 1000 +
              action?.payload?.monthlyCreditExpired?.nanoseconds / 1000000,
          ),
        ) || '';
      state.freeTrialTimeStamp = action?.payload?.freeTrialTimeStamp || '';
      state.customPlan = action?.payload?.customPlan || false;
      state.numberCreditToRenew = action?.payload?.numberCreditToRenew || 0;
    },
  },
});

export const {
  resetUsageStore,
  setCreditNumber,
  setNTrial,
  updateUsageStore,
  setMonthlyCreditNumber,
  setYearlyPlan,
  setMonthlyPlan,
} = usageSlice.actions;

export const selectCreditNumber = state => state?.usage?.creditNumber;
export const selectNTrial = state => state?.usage?.nTrial;
export const selectMonthlyCreditNumber = state =>
  state?.usage?.monthlyCreditNumber;
export const selectYearlyPlan = state => state?.usage?.yearlyPlan;
export const selectMonthlyPlan = state => state?.usage?.monthlyPlan;

export const selectFreeTrialTimeStamp = state =>
  state?.usage?.freeTrialTimeStamp;

export const selectUsage = state => state.usage;

export default usageSlice.reducer;
