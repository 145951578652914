import React from 'react';

const RadialGradient = ({
    startOpacity = 0.3, // Default start opacity
    endOpacity = 0, // Default end opacity
    radius = '50%', // Default radius
    centerX = '50%', // Default horizontal center
    centerY = '50%', // Default vertical center
    color = '#78BD32', // Default color
    numberOfStops = 5, // Default number of fade steps for smoothness
}) => {
    // Calculate smooth fade stops dynamically
    const stops = Array.from({ length: numberOfStops + 1 }, (_, i) => {
        const offset = (i / numberOfStops) * 100; // Evenly spaced offsets (0%, 20%, ...)

        const opacity =
            startOpacity - (i / numberOfStops) * (startOpacity - endOpacity); // Smoothly decrease opacity

        return { offset: `${offset}%`, opacity };
    });

    // Create the gradient stops for the SVG
    const gradientStops = stops.map(({ offset, opacity }, index) => (
        <stop
            key={index}
            offset={offset}
            stopColor={color}
            stopOpacity={opacity}
        />
    ));

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        >
            <defs>
                <radialGradient id="grad" cx={centerX} cy={centerY} r={radius} fx={centerX} fy={centerY}>
                    {gradientStops}
                </radialGradient>
            </defs>
            <rect
                width="100%"
                height="100%"
                fill="url(#grad)"
            />
        </svg>
    );
};

export default RadialGradient;
