import React from "react";
import OnBoardingContainer from "../components/OnBoardingContainer";
import AthleteRedirectionScreen from "screen/profile/AthleteRedirectionScreen";


function OnBoardingAthleteRedirection() {

    return (
        <OnBoardingContainer previous >
            <AthleteRedirectionScreen onBoarding />
        </OnBoardingContainer>
    )

}


export default OnBoardingAthleteRedirection;