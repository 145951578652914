import React, { useEffect } from "react";

import OnBoardingContainer from "../components/OnBoardingContainer";
import OnBoardingTemplate from "../components/OnBoardingTemplate";
import TextComponent from "components/atoms/TextComponent";

import { onBoardingTranslate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { BOLD, LIGHT } from "constant/constants";

import { colors } from "themes";

function DataDrivenApproach() {

    useEffect(() => {
        mpTrack('Web App OnBoarding Professional Data Driven Approach Screen Viewed');
    }, []);

    return (
        <OnBoardingContainer previous next>
            <OnBoardingTemplate
                title={onBoardingTranslate('DataDrivenApproachTitle')}
                text={onBoardingTranslate('ScienceDrivenAnalysisBVDesc')}
            >
                <TextComponent
                    text={onBoardingTranslate('ScienceDrivenAnalysisOverPronation')}
                    weight={LIGHT}
                    sx={{ color: colors.WHITE_OPACITY_60, width: '75%', textAlign: 'center', fontSize: '1rem' }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '3vh', height: '35vh' }}>
                    <img
                        src={require('assets/images/pronationAnalysisImages/neutralL.png')}
                        alt="neutralL"
                        style={{ height: '90%' }}
                    />
                    <img
                        src={require('assets/images/pronationAnalysisImages/overPronationR.png')}
                        alt="overPronationR"
                        style={{ height: '90%' }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '2vh' }}>
                    <TextComponent
                        text={onBoardingTranslate('RightFootOverPronation') + ':'}
                        weight={BOLD}
                        sx={{ textAlign: 'center', fontSize: '2rem' }}
                    />
                    <TextComponent
                        text={' -15°'}
                        weight={BOLD}
                        sx={{ fontSize: '2rem', textAlign: 'center', color: colors.RED, marginLeft: '1vw' }}
                    />
                </div>
            </OnBoardingTemplate>
        </OnBoardingContainer>
    )
}

export default DataDrivenApproach;