import React, { useEffect } from "react";

import OnBoardingContainer from "../../components/OnBoardingContainer";
import OnBoardingTemplate from "../../components/OnBoardingTemplate";

import { onBoardingTranslate } from "services/remoteConfig";
import { mpTrack } from "services/mixpanel";

import { checkUserPermission } from "utils/utils";

import { ANALYSIS_ACCESSIBILITY_COACH_URL, ANALYSIS_ACCESSIBILITY_PRO_URL } from "constant/onBoardingConstants";
import { COACH, PROFESSIONAL } from "constant/constants";

function AnalysisAccessibility() {

    useEffect(() => {
        checkUserPermission([COACH], true)
            ? mpTrack('Web App OnBoarding Coach Analysis Accessibility Screen Viewed')
            : mpTrack('Web App OnBoarding Professional Analysis Accessibility Screen Viewed');
    }, []);

    return (
        <OnBoardingContainer previous next>
            <OnBoardingTemplate
                title={onBoardingTranslate('AnalysisAccessibilityTitle')}
                text={onBoardingTranslate('AnalysisAccessibilityDesc')}
            >
                <video
                    src={checkUserPermission([PROFESSIONAL], true) ? ANALYSIS_ACCESSIBILITY_PRO_URL : ANALYSIS_ACCESSIBILITY_COACH_URL}
                    alt="analysisAccessibility"
                    loop
                    autoPlay
                    muted
                    style={{ borderRadius: 10, display: 'flex', height: '100%' }}
                />
            </OnBoardingTemplate>
        </OnBoardingContainer>
    )
}

export default AnalysisAccessibility;