import TextComponent from 'components/atoms/TextComponent';
import React from 'react';
import { translate } from 'services/remoteConfig';
import AuthContainer from './components/AuthContainer';
import ButtonComponent from 'components/atoms/ButtonComponent';
import { ORANGE, REVERSE } from 'constant/constants';
import { colors } from 'themes';
import SsoButtons from './components/SsoButtons';
import { useNavigate } from 'react-router-dom';
import { useProfileChanges } from 'hooks/useProfileChanges';

function LoginOrRegister() {
  const navigate = useNavigate();
  useProfileChanges();

  return (
    <AuthContainer>
      <TextComponent
        text={translate('Welcome')}
        variant={'h2'}
        sx={styles.textCenter}
      />
      <TextComponent
        text={translate('RegisterSubTitle')}
        variant={'body1'}
        sx={{ ...styles.textCenter, marginTop: '3%', width: '80%' }}
        weight={'400'}
      />
      <div
        style={{
          width: '60%',
          alignItems: 'center',
          marginTop: 50,
          padding: '5%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 15,
          minHeight: 100,
          justifyContent: 'center',
        }}>
        <ButtonComponent
          type={ORANGE}
          title={translate('Login')}
          onClick={() => {
            navigate('/login');
          }}
          sx={{
            width: '90%',
            height: '3rem',
            borderRadius: '20rem',
            fontSize: '1rem',
            fontWeight: '600',
            marginBottom: 1,
          }}
        />
        <ButtonComponent
          type={REVERSE}
          title={translate('SignUp')}
          onClick={() => {
            navigate('/register');
          }}
          sx={{
            width: '90%',
            height: '3rem',
            borderRadius: '20rem',
            fontSize: '1rem',
            fontWeight: '600',
          }}
        />
      </div>
    </AuthContainer>
  );
}
const styles = {
  container: {
    display: 'flex',
  },
  childrenContainer: {
    width: '40%',
    float: 'right',
    top: 0,
    right: 0,
    position: 'absolute',
    marginTop: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  textCenter: {
    textAlign: 'center',
  },
};
export default LoginOrRegister;
