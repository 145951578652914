import {store} from 'store';
import mixpanel from 'mixpanel-browser';
import {intercomInit, intercomLogEvent} from './intercom';
import {USER_LOCALE} from './remoteConfig';
import {auth} from 'utils/firebaseInit';

const userProfile = store.getState().user;
const UsageStore = store.getState().usage;

mixpanel.init(process.env.REACT_APP_MIXPANEL, {verbose: false, debug: false});

export async function mpTrack(key, info) {
  intercomLogEvent(key);
  if (userProfile?.emailAddress?.includes('@ochy.io')) return;

  try {
    mixpanel.track(key, {
      ID: userProfile.sUserID,
      Email: userProfile.emailAddress,
      FullName: userProfile.fullName,
      'User Type': userProfile.userType,
      Credits: UsageStore.creditNumber,
      MonthlyCredit: UsageStore.monthlyCreditNumber,
    });
  } catch (error) {
    console.log(error);
  }
}

export function mpIdentify() {
  const user = store?.getState()?.user;
  try {
    if (user) {
      mixpanel.identify(user?.userID);
      intercomInit();
      mixpanel.people.set({
        $UId: user?.userID,
        $name: user?.fullName,
        $email: user?.emailAddress,
        'User Type': user?.type,
        Language: USER_LOCALE,
      });
    }
  } catch (error) {
    console.log(error);
  }
}
