import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useProjectChanges } from 'hooks/useProjectChanges';
import { useProfileChanges } from 'hooks/useProfileChanges';
import { useUsageChanges } from 'hooks/useUsageChanges';

import AllAnalysis from './components/AllAnalysis';
import NoVideos from './components/NoVideos';
import AthleteInfos from './components/AthleteInfos';
import TextComponent from 'components/atoms/TextComponent';
import ButtonComponent from 'components/atoms/ButtonComponent';

import { intercomInit } from 'services/intercom';
import { translate } from 'services/remoteConfig';
import { mpIdentify, mpTrack } from 'services/mixpanel';

import { store } from 'store';
import { selectAthleteSelected } from 'slices/athleteSlice';


import { ATHLETE, BLUE_BORDER, H4, ORANGE } from 'constant/constants';
import { updateProfile } from 'services/userApi';
import { updateProfileStore } from 'slices/userSlice';
import LoadingComponent from 'components/atoms/LoadingComponent';
import { checkUserPermission } from 'utils/utils';
import AthleteRedirectionScreen from 'screen/profile/AthleteRedirectionScreen';

export default function AnalysisList({ loginLoading }) {
  useProfileChanges();
  useUsageChanges();
  const { projects, projectsLoading } = useProjectChanges();
  const user = store?.getState()?.user;


  const dispatch = useDispatch();

  const athleteSlice = useSelector(selectAthleteSelected);

  const [comparedAnalysis, setComparedAnalysis] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('segmentToWork');
    mpTrack('Web App Analysis List Screen Viewed');
    if (user) {
      mpIdentify();
      intercomInit();
    }
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (user?.onBoarded || loginLoading) return;
    updateProfile({ onBoarded: true });
    dispatch(updateProfileStore({ onBoarded: true }));
  }, []);


  if (checkUserPermission([ATHLETE])) {
    return (
      <AthleteRedirectionScreen />
    )
  }


  if (projectsLoading || loginLoading) return <div style={{
    justifyContent: 'center', alignItems: 'center', height: "80vh", display: "flex",
  }}>
    <LoadingComponent size={125} />
  </div>

  if (athleteSlice && !projectsLoading && projects?.analysis?.length === 0) {
    return (
      <>
        {Object.keys(athleteSlice).length !== 0 ? <AthleteInfos /> : null}
        <NoVideos />;
      </>
    );
  }
  return (
    <div>
      {Object.keys(athleteSlice).length !== 0 ? <AthleteInfos /> : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
          marginTop: 10,
        }}>
        <TextComponent
          variant={H4}
          text={translate('Analysis')}
          sx={{ marginLeft: 3 }}
        />
        <ButtonComponent
          type={BLUE_BORDER}
          title={translate('Compare')}
          onClick={() => {
            if (comparedAnalysis) {
              setComparedAnalysis(null);
            } else {
              setComparedAnalysis([]);
            }
            mpTrack('Web App Analysis Compare Button Clicked');
          }}
        />
        {comparedAnalysis ? (
          <ButtonComponent
            type={ORANGE}
            title={translate('Validate')}
            disabled={comparedAnalysis?.length !== 2}
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate('/compare-analysis', {
                state: { comparedAnalysis },
              });
            }}
          />
        ) : null}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AllAnalysis
          comparedAnalysis={comparedAnalysis}
          setComparedAnalysis={setComparedAnalysis}
        />
      </div>
    </div>
  );
}
