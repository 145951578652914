import ErrorPage from "Error-page";
import React from "react";
import { Route, Routes } from "react-router-dom";
import OnBoardingExercises from "screen/auth/registration/onBoarding/athleteOnBoarding/analysis/OnBoardingExercises";
import OnBoardingAtheteProgress from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAtheteProgress";
import OnBoardingAthleteAnalysisIntro from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteAnalysisIntro";
import OnBoardingAthleteCompare from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteCompare";
import OnBoardingAthleteGender from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteGender";
import OnBoardingAthleteHeightAndWeight from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteHeightAndWeight";
import OnBoardingAthleteInfos from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteInfos";
import OnBoardingAthleteRunningFrequency from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteRunningFrequency";
import OnBoardingAthleteRunningLevel from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteRunningLevel";
import OnBoardingAthleteTypology from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteTypology";
import OnBoardingAthleteTypologyWelcome from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteTypologyWelcome";
import PersonnalInfosText from "screen/auth/registration/onBoarding/athleteOnBoarding/PersonnalInfosText";
import OnBoardingAthleteAnalysisWrapper from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteAnalysisWrapper";
import OnBoardingUserNumber from "screen/auth/registration/onBoarding/common/userTypeSpecific/OnBoardingUserNumber";
import OnBoardingAthleteRedirection from "screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteRedirection";


function AthleteStack() {
    return (
        <Routes>
            <Route
                path="/app-redirection"
                element={<OnBoardingAthleteRedirection />}
                errorElement={<ErrorPage />} />

            {/* <Route
                path="/welcome"
                element={<OnBoardingUserNumber />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/typology"
                element={<OnBoardingAthleteTypology />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/typology-welcome"
                element={<OnBoardingAthleteTypologyWelcome />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/personnal-text"
                element={<PersonnalInfosText />}
                errorElement={<ErrorPage />}
            />

            <Route
                path="/gender"
                element={<OnBoardingAthleteGender />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/running-level"
                element={<OnBoardingAthleteRunningLevel />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/running-frequency"
                element={<OnBoardingAthleteRunningFrequency />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/personnalised-analysis"
                element={<OnBoardingAthleteInfos />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/height-weight"
                element={<OnBoardingAthleteHeightAndWeight />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/analysis-introduction"
                element={<OnBoardingAthleteAnalysisIntro />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/analyse"
                element={<OnBoardingAthleteAnalysisWrapper />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/train"
                element={<OnBoardingExercises />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/compare"
                element={<OnBoardingAthleteCompare />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/progress"
                element={<OnBoardingAtheteProgress />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/profile-creation"
                element={<OnBoardingProfileCreation />}
                errorElement={<ErrorPage />}
            /> */}
        </Routes>
    )
}

export default AthleteStack;