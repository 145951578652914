
export const videoTutosContent = [
  {
    vertical: false,
    videoUri: 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fvideos%2FVideoTuto%2FgoodVideo.mp4?alt=media',
    videoTextGood: [
      {

        text: 'TenSecMaxPerVideo',
      },
      {
        text: 'FullBodyVisible',
      },
      {

        text: 'OnePersonPerVideo',
      },
      {
        text: 'FourStrideMinimum',
      },
      {

        text: 'TakeVideoHorizontal',
      },
    ],
    videoTextBad: [
      {

        text: 'DontFollowRunner',
      },
      {

        text: 'DontFilmBackViewOrFrontView',
      },
    ],
  },
  {
    vertical: true,
    videoUri: 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FGoodVideos%2FTreadmillPreview.mp4?alt=media',
    videoTextGood: [
      {

        text: 'TenSecMaxPerVideo',
      },
      {
        text: 'FullBodyVisible',
      },
      {
        text: 'FourStrideMinimum',
      },
      {

        text: 'TakeVideoSideView',
      },
    ],
    videoTextBad: [
      {

        text: 'DontMovePhone',
      },
      {

        text: 'MultiplePerson',
      },
    ],
  },
  {
    vertical: false,
    videoUri: 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FGoodVideos%2FTreadmillPreviewPro.mp4?alt=media',
    videoTextGood: [
      {

        text: 'TenSecMaxPerVideo',
      },
      {
        text: 'FullBodyVisible',
      },
      {
        text: 'FourStrideMinimum',
      },
      {

        text: 'TakeVideoSideView',
      },
    ],
    videoTextBad: [
      {

        text: 'DontMovePhone',
      },
      {

        text: 'MultiplePerson',
      },
    ],
  },
  {
    vertical: true,
    videoUri: 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FGoodVideos%2FbackViewPreview.mp4?alt=media',
    videoTextGood: [
      {

        text: 'WalkOnTreadmill',
      },
      {
        text: 'FullBodyVisible',
      },
      {
        text: 'TenSecMaxPerVideo',
      },
      {
        text: 'FourStrideMinimum',
      },
      {
        text: 'TakeVideoVertical',
      },
    ],
    videoTextBad: [
      {
        text: 'DontFilmSideView',
      },
      {
        text: 'MultiplePerson',
      },
    ],
  },
];

export const videoTutoTitle = [
  'SideViewRunning',
  'SideViewTreadmill',
  'BackViewTreadmill',
];
