import ButtonComponent from "components/atoms/ButtonComponent";
import PickerComponent from "components/atoms/PickerComponent";
import TextComponent from "components/atoms/TextComponent";
import TextInput from "components/atoms/TextInput";
import { BOLD, ORANGE } from "constant/constants";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "themes";
import { createTextObjectReusable } from "utils/utils";



function AddFieldInDb({ setEditDb, setUserInfo, setSidePanContent, modalRef, sidePanContent }) {

    const booleanRef = useRef();
    const booleanConfig = ['true', 'false'];
    const typeConfig = ['String', 'Number', 'Boolean'];
    const [disabled, setDisabled] = useState(true);
    const [fieldToAdd, setFieldToAdd] = useState({
        field: '',
        type: 'String',
        value: ''
    });

    useEffect(() => {
        if (fieldToAdd?.field && fieldToAdd?.value !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [fieldToAdd]);

    function handleInputChange(e, key, bool = false) {
        if (bool) {
            setFieldToAdd((prevFieldToAdd) => ({
                ...prevFieldToAdd,
                [key]: String(e).toLowerCase() === 'true'
            }));
            return;
        }
        setFieldToAdd((prevFieldToAdd) => ({
            ...prevFieldToAdd,
            [key]: fieldToAdd?.type === 'Number' ? Number(e.target.value) : e.target.value
        }));


    }

    function handleTypeChange(type) {
        setFieldToAdd((prevFieldToAdd) => ({
            ...prevFieldToAdd,
            type: type
        }));
    }


    async function onValidateClick() {
        const toUpdate = { ...sidePanContent?.data, [fieldToAdd?.field]: fieldToAdd?.value };
        console.log('toUpdate', toUpdate);

        setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            [sidePanContent?.type]: {
                ...prevUserInfo[sidePanContent?.type],
                ...toUpdate
            }
        }));
        setSidePanContent({ data: toUpdate, type: sidePanContent.type });
        setEditDb(true);
        setFieldToAdd({
            field: '',
            type: 'String',
            value: ''
        });
        modalRef.current.setClose();
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>


            <div style={{ width: '80%', display: 'flex', flexDirection: 'row', marginTop: '2vh', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '2vh' }}>
                    <TextComponent
                        text={'Field: '}
                        weight={BOLD}
                        sx={{ color: colors.TEXT_LIGHT, marginBottom: '1vh' }}
                    />
                    <TextInput
                        onChange={(e) => handleInputChange(e, 'field')}
                    />
                </div>
                <TextComponent
                    text={'='}
                    weight={BOLD}
                    sx={{ color: colors.WHITE, textAlign: 'center', marginRight: '2vh', marginLeft: '2vh' }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '2vh' }}>
                    <TextComponent
                        text={'Type: '}
                        weight={BOLD}
                        sx={{ color: colors.TEXT_LIGHT, marginBottom: '1vh' }}
                    />
                    <PickerComponent
                        config={typeConfig}
                        height={'4vh'}
                        handleValueChanges={handleTypeChange}
                        ref={booleanRef}
                        defaultValue={typeConfig[0]} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '2vh' }}>
                    <TextComponent
                        text={'Value: '}
                        weight={BOLD}
                        sx={{ color: colors.TEXT_LIGHT, marginBottom: '1vh' }}
                    />
                    {fieldToAdd?.type === 'Boolean' ?
                        <PickerComponent
                            config={booleanConfig}
                            addedStyle={{ height: '4vh' }}
                            handleValueChanges={(e) => handleInputChange(e, 'value', true)}
                            ref={booleanRef}
                            defaultValue={booleanConfig[0]} />
                        :
                        <TextInput
                            name={'Field name'}
                            onChange={(e) => handleInputChange(e, 'value')}
                            type={fieldToAdd?.type === 'Number' ? 'number' : 'text'}
                        />}
                </div>
            </div>

            <ButtonComponent
                title={'Add field'}
                sx={{ marginTop: '2vh', marginBottom: '2vh' }}
                onClick={onValidateClick}
                disabled={disabled}
                type={ORANGE}
            />


        </div>
    );
}

export default AddFieldInDb;