import TextComponent from "components/atoms/TextComponent";
import { ATHLETE, BOLD, CONTAINED, H5 } from "constant/constants";
import React, { useEffect, useState } from "react";
import { colors, images } from "themes";
import { createTextObjectReusable } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectAccountUid, setAccountUid, setCurrentVideoId } from "slices/adminSlice";
import { getUsage } from "services/usageApi";
import { getUidFromEmail, getUserInfoFromId } from "services/userApi";
import { getProjects } from "services/projectApi";
import TextInput from "components/atoms/TextInput";
import { Button, styled } from "@mui/material";
import { ReactComponent as LoopIcon } from 'assets/svg/loop.svg';
import './styles/BackOffice.css';


function BackOfficeUser({ setSidePanContent, sidePanRef, userInfo, setUserInfo }) {

    const [email, setEmail] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [uid, setUid] = useState(null);
    const accountUid = useSelector(selectAccountUid);
    const [disabled, setDisabled] = useState(!accountUid);


    const dispatch = useDispatch();

    useEffect(() => {

        if (!accountUid) {
            setDisabled(true);
            setEmail('');
            setUid('');
            setUserInfo(null);
        }

        const fetchData = async () => {
            if (accountUid && !uid) {
                setUid(accountUid);
                await handleSubmit(accountUid);
            }

        };

        fetchData();
    }, [accountUid, uid, userInfo])

    const handleEmailChange = e => {
        e.stopPropagation();
        setEmail(e?.target?.value);
        setErrorMessage(null);
        if (e?.target?.value) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    async function handleSubmit(newUid) {
        setUserInfo(null);
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const uidToUse = emailRegex?.test(email) ? await getUidFromEmail(email) : email || newUid;

        if (uidToUse?.error) {
            setErrorMessage('No user found');
            return;
        }

        if (uidToUse && uidToUse !== uid) {
            setUid(uidToUse);
        }

        if (uidToUse) {
            dispatch(setAccountUid(uidToUse));
            dispatch(setCurrentVideoId(null));
            const userInfo = await getUserInfoFromId(uidToUse);
            if (!userInfo) {
                setErrorMessage('No user found');
                return;
            }
            let usage = await getUsage(uidToUse);

            const convertToDate = (timestamp) => {
                if (timestamp?.seconds && timestamp?.nanoseconds) {
                    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
                    return new Date(milliseconds);
                }
                return null;
            };
            usage = {
                ...usage,
                subscriptionExpiresDate: convertToDate(usage?.subscriptionExpiresDate),
                monthlyCreditExpired: convertToDate(usage?.monthlyCreditExpired),
            };
            const { projects, analysisDetails } = await getProjects(uidToUse);

            setUserInfo({ user: userInfo, usage: usage, projects: { projects: projects, analysisDetails: analysisDetails } });
        }
    }


    function handleClick() {
        if (!userInfo?.user) return;
        setSidePanContent({ data: userInfo?.user, type: 'user' });
        sidePanRef.current.open();
    }

    return (
        <div className={'category-container'} data-type={userInfo?.user ? 'user' : null} onClick={handleClick}>
            <TextComponent
                text={!userInfo ? 'Search a User' : 'User'}
                variant={H5}
                weight={BOLD}
                sx={{ color: colors.WHITE, textAlign: 'center' }} />
            <div style={{ display: 'flex', marginTop: '4vh', marginLeft: '1vw', alignItems: 'center', marginBottom: '3vh' }} onClick={(e) => e.stopPropagation()}>
                <TextInput
                    label={"Email or uid"}
                    onChange={handleEmailChange}
                    value={uid}
                    handleSubmit={() => handleSubmit(email)}
                    error={errorMessage}
                    helperText={errorMessage}
                />
                <IconButton variant={CONTAINED} sx={{ backgroundColor: colors.SECONDARY, marginLeft: '1vw' }} disabled={disabled} onClick={() => handleSubmit(email)}>
                    <LoopIcon />
                </IconButton>
            </div>
            {userInfo?.user &&
                (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <img style={{ height: '10vh', width: '10vh', borderRadius: 20 }} src={userInfo?.user?.photo ? userInfo?.user?.photo : userInfo?.user?.type === 'athlete' ? images?.ATHLETE_BASE : userInfo?.user?.type === 'coach' ? images?.COACH_DEFAULT_PICTURE : images?.PROFESSIONAL_DEFAULT_PICTURE} alt="profile" />
                                <TextComponent
                                    text={`${userInfo?.user?.fullName}`}
                                    variant={H5}
                                    weight={BOLD}
                                    sx={{ color: colors.WHITE }}
                                />
                            </div>
                            {userInfo?.user?.type !== ATHLETE &&
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                    <img style={{ height: '10vh', width: '10vh', borderRadius: 20 }} src={userInfo?.user?.brandLogo ? userInfo?.user?.brandLogo : images?.OCHY_LOGO} alt="profile" />
                                    <TextComponent
                                        text={`${userInfo?.user?.brandName}`}
                                        variant={H5}
                                        weight={BOLD}
                                        sx={{ color: colors.WHITE }}
                                    />
                                </div>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '2vh' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2vw' }}>
                                <TextComponent
                                    multipleText={createTextObjectReusable(
                                        `[[Email]] : ${userInfo?.user?.emailAddress}`,
                                        { style: { color: colors.WHITE } },
                                        {},
                                        {
                                            style: { color: colors.SECONDARY },
                                            weight: BOLD,
                                        },
                                    )} />

                                <TextComponent
                                    multipleText={createTextObjectReusable(
                                        `[[Register date]] : ${new Date(userInfo?.user?.registerDate).toLocaleDateString()}`,
                                        { style: { color: colors.WHITE } },
                                        {},
                                        {
                                            style: { color: colors.SECONDARY },
                                            weight: BOLD,
                                        },
                                    )} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2vw' }}>
                                <TextComponent
                                    multipleText={createTextObjectReusable(
                                        `[[Type]] : ${userInfo?.user?.type}`,
                                        { style: { color: colors.WHITE } },
                                        {},
                                        {
                                            style: { color: colors.SECONDARY },
                                            weight: BOLD,
                                        },
                                    )} />
                                <TextComponent
                                    multipleText={createTextObjectReusable(
                                        `[[Typology]] : ${userInfo?.user?.typology}`,
                                        { style: { color: colors.WHITE } },
                                        {},
                                        {
                                            style: { color: colors.SECONDARY },
                                            weight: BOLD,
                                        },
                                    )} />
                                {userInfo?.user?.type !== ATHLETE && <TextComponent
                                    multipleText={createTextObjectReusable(
                                        `[[Specialization]] : ${userInfo?.user?.specialization?.map(specialization => specialization)?.join(', ')}`,
                                        { style: { color: colors.WHITE } },
                                        {},
                                        {
                                            style: { color: colors.SECONDARY },
                                            weight: BOLD,
                                        },
                                    )} />}
                            </div>
                        </div>
                    </>
                )}






        </div>
    )


}


const IconButton = styled(Button)({
    '&:hover': {
        backgroundColor: colors.OCHY_BLUE,
        transition: '0.2s ease-in-out',
    },
    '&:disabled': {
        backgroundColor: '#2E2E2E',
        color: colors.TEXT_GRAY,
    },
});


export default BackOfficeUser;