import ErrorPage from "Error-page";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes } from "react-router-dom";
import CommonStack from "./CommonStack";
import AthleteStack from "./AthleteStack";
import CoachStack from "./CoachStack";
import ProfessionalStack from "./ProfessionalStack";
import { ATHLETE, COACH, PROFESSIONAL, REGISTRATION_DATA } from "constant/constants";

function RegistrationRoutes() {

    const userType = JSON.parse(
        localStorage.getItem(REGISTRATION_DATA),
    )?.userType;

    return (
        <ErrorBoundary FallbackComponent={<ErrorPage />}>
            <Routes>
                <Route path="/" element={<Navigate to="/registration" replace />} />
                <Route path="/registration/*" element={<CommonStack />} />

                {userType === ATHLETE && <Route path="/registration/athlete/*" element={<AthleteStack />} />}
                {userType === COACH && <Route path="/registration/coach/*" element={<CoachStack />} />}
                {userType === PROFESSIONAL && <Route path="/registration/professional/*" element={<ProfessionalStack />} />}

                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </ErrorBoundary>
    );
}

export default RegistrationRoutes;
