import {EXEMPLE_ANALYSIS, EXEMPLE_ANALYSIS_BACKVIEW} from 'constant/product';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from 'firebase/firestore';

import {auth, firestore} from 'utils/firebaseInit';
import {USER_LOCALE} from './remoteConfig';

const ANALYSIS_COLLECTION = 'analysis';
const LOGS_COLLECTION = 'analysisLogs';
const PROJECT_COLLECTION = 'projects';
const PROJECT_SUB_COLLECTION = 'analysisDetails';
const EXEMPLE_ANALYSIS_COLLECTION = 'exempleAnalysis';

export async function getProjects(uid = null) {
  if (auth) {
    const userId = uid || auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = await getDoc(ref);
      const subCollectionRef = collection(ref, PROJECT_SUB_COLLECTION);

      if (docs.exists()) {
        const data = docs.data().analysis;
        const querySnapshot = await getDocs(subCollectionRef);
        const subCollectionData = [];
        querySnapshot?.forEach(doc => {
          subCollectionData?.push(doc.data());
        });

        return {
          projects: data,
          analysisDetails: subCollectionData,
        };
      } else {
        return null;
      }
    } catch (error) {
      console.log('Error in getProjects', error);
    }
  }
}

export async function deleteAnalysisFromProjects(analysisId, uid = null) {
  if (auth) {
    const userId = uid || auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);
      if ((await docs).exists()) {
        const data = (await docs).data().analysis;
        data?.forEach((element, index) => {
          if (element === analysisId) {
            data?.splice(index, 1);
          }
        });
        setDoc(ref, {analysis: data});
        const subCollectionRef = collection(ref, PROJECT_SUB_COLLECTION);
        const analysisDocRef = doc(subCollectionRef, analysisId);
        const analysisDocs = getDoc(analysisDocRef);
        if ((await analysisDocs).exists()) {
          await deleteDoc(analysisDocRef);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function updateAnalysisInProject(
  updatedData,
  analysisId,
  merge = true,
) {
  if (auth) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const userDocRef = doc(collectionRef, userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        const analysis = data?.analysis || [];
        const analysisIndex = analysis.findIndex(id => id === analysisId);
        if (analysisIndex === -1) {
          console.log(
            `L'analyse avec ID ${analysisId} n'existe pas dans le tableau.`,
          );
          return;
        }
        const subCollectionRef = collection(userDocRef, PROJECT_SUB_COLLECTION);
        const analysisDocRef = doc(subCollectionRef, analysisId);

        const analysisDoc = await getDoc(analysisDocRef);
        if (analysisDoc.exists()) {
          await setDoc(analysisDocRef, updatedData, {merge: merge});
        } else {
          console.log(
            `L'analyse ${analysisId} n'existe pas dans la sous-collection.`,
          );
          return;
        }
      } else {
        console.warn("Le document utilisateur n'existe pas.");
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function createNewAnalysis(data, tag, analysis_type) {
  const user = auth?.currentUser;
  if (user) {
    if (!data?.uploadVideoId) {
      console.log("Erreur : l'ID du document (uploadVideoId) est manquant.");
      return;
    }

    data.owner = user?.uid;
    if (tag) data.tag = tag;
    else data.tag = {};

    const newAnalysis = {
      data: data,
      date: new Date().toISOString(),
      status: 'pending',
    };

    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, user?.uid);

      const analysisDetailsRef = collection(ref, PROJECT_SUB_COLLECTION);

      const subCollectionRef = doc(analysisDetailsRef, data?.uploadVideoId);

      await setDoc(subCollectionRef, newAnalysis, {merge: true});

      const analysisCollectionRef = collection(firestore, ANALYSIS_COLLECTION);
      const analysisDocRef = doc(analysisCollectionRef, data?.uploadVideoId);

      await setDoc(
        analysisDocRef,
        {
          analysisReference: subCollectionRef,
          date: new Date().toISOString(),
          data: data,
        },
        {merge: true},
      );

      const logsCollectionRef = collection(firestore, LOGS_COLLECTION);
      const logsDoc = doc(logsCollectionRef, data?.uploadVideoId);
      await setDoc(
        logsDoc,
        {
          date: new Date().toISOString(),
          type: 'analysis',
          analysis_type: analysis_type,
          user: user.uid,
          data: {
            analysisId: data?.uploadVideoId,
            videoId: data?.uploadVideoId,
            tag: tag,
          },
        },
        {merge: true},
      );
    } catch (e) {
      console.log('Error in createNewAnalysis ', e);
    }
  }
}

export async function addNewAnalysisInUserProject(analysisId, userId) {
  const user = auth?.currentUser;
  if (user) {
    try {
      addAnalysisInProjects(analysisId);
      if (userId) {
        addAnalysisInProjects(analysisId, userId);
      }
    } catch (e) {
      console.log('error in add', e);
    }
  }
}

export async function addAnalysisInProjects(analysisId, userId) {
  //need to check here
  const user = auth?.currentUser;
  let arr = [];
  if (user) {
    let docId;
    if (!userId) docId = user?.uid;
    else docId = userId;
    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, docId);
      const docs = getDoc(ref);
      const projs = (await docs).data();
      if (!projs) arr = [];
      else {
        arr = [...projs?.analysis];
      }
      if (!arr?.find(ele => ele === analysisId)) arr?.push(analysisId);
      setDoc(ref, {analysis: arr}, {merge: true});
    } catch (e) {
      console.log('error in add', e);
    }
  }
}

export async function initProjects() {
  try {
    const user = auth?.currentUser;
    if (user) {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, user?.uid);
      const docs = await getDoc(ref);
      const projs = docs.data();
      const language = USER_LOCALE;

      if (!projs || !projs?.analysis) {
        await setDoc(
          ref,
          {
            analysis: [
              EXEMPLE_ANALYSIS[language],
              EXEMPLE_ANALYSIS_BACKVIEW[language],
            ],
          },
          {merge: true},
        );
      }

      const analysisDetailsRef = collection(ref, PROJECT_SUB_COLLECTION);
      const exempleAnalysisCollection = collection(
        firestore,
        EXEMPLE_ANALYSIS_COLLECTION,
      );

      const snapshot = await getDocs(analysisDetailsRef);
      if (snapshot.empty) {
        const exempleAnalysisSideViewDocRef = doc(
          exempleAnalysisCollection,
          EXEMPLE_ANALYSIS[language],
        );

        const exempleAnalysisSideViewDocSnapshot = await getDoc(
          exempleAnalysisSideViewDocRef,
        );
        const exempleAnalysisSideViewData =
          exempleAnalysisSideViewDocSnapshot?.data();

        if (exempleAnalysisSideViewData) {
          const exampleSideViewAnalysisDetails = doc(
            analysisDetailsRef,
            EXEMPLE_ANALYSIS[language],
          );
          await setDoc(
            exampleSideViewAnalysisDetails,
            exempleAnalysisSideViewData,
          );
        }

        const exempleAnalysisBackViewDocRef = doc(
          exempleAnalysisCollection,
          EXEMPLE_ANALYSIS_BACKVIEW[language],
        );

        const exempleAnalysisBackViewDocSnapshot = await getDoc(
          exempleAnalysisBackViewDocRef,
        );
        const exempleAnalysisBackViewData =
          exempleAnalysisBackViewDocSnapshot?.data();

        if (exempleAnalysisBackViewData) {
          const exampleBackViewAnalysisDetails = doc(
            analysisDetailsRef,
            EXEMPLE_ANALYSIS_BACKVIEW[language],
          );
          await setDoc(
            exampleBackViewAnalysisDetails,
            exempleAnalysisBackViewData,
          );
        }
      }
    }
  } catch (error) {
    console.error('Error in initProjects : ', error);
  }
}
