import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  lstItems: [],
  lstItemsData: [],
  lstItemPending: [],
  sSearch: '',
};

export const userItemSlice = createSlice({
  name: 'userItems',
  initialState,
  reducers: {
    resetUserItemStore: (state, action) => {
      state.lstItems = [];
      state.sSearch = '';
      state.lstItemsData = [];
      state.lstItemPending = [];
    },
    setLstItems: (state, action) => {
      state.lstItems = action?.payload;
    },
    setLstItemsData: (state, action) => {
      state.lstItemsData = action?.payload;
    },
    setLstItemPending: (state, action) => {
      state.lstItemPending = action?.payload;
    },
    setSearch: (state, action) => {
      state.sSearch = action?.payload;
    },
  },
});

export const {
  resetUserItemStore,
  setLstItems,
  setSearch,
  setLstItemsData,
  setLstItemPending,
} = userItemSlice.actions;

export const selectListItems = state => state?.userItems?.lstItems;
export const selectListItemsData = state => state?.userItems?.lstItemsData;
export const selectListItemsPending = state => state?.userItems?.lstItemPending;

export const searchItemById = (state, id) => {
  return state.userItems.lstItems.find(item => item.id === id);
};

export default userItemSlice.reducer;
